import * as yup from 'yup'

export const schema = yup
  .object()
  .shape({
    initialValue: yup
      .string()
      .required('Insira um valor.')
      .typeError('Insira um valor.'),
    monthlyValue: yup
      .string()
      .required('Insira um valor monetário.')
      .typeError('Insira um valor monetário.'),
    interestRate: yup
      .string()
      .required('Insira um valor monetário.')
      .typeError('Insira um valor monetário.'),
    interestPeriod: yup
      .string()
      .required('Insira um valor numérico.')
      .typeError('Insira um valor numérico.'),
    periodInMonths: yup
      .number()
      .required('Insira um valor numérico.')
      .typeError('Insira um valor numérico.')
      .min(1, 'Insira um valor maior que 1.')
      .max(100 * 12, 'Insira um valor menor que 1200.'),
  })
  .required()
