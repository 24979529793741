import styled from 'styled-components'

type ButtonProps = {
  variant?: 'primary' | 'secondary'
}

export const Button = styled.button<ButtonProps>`
  height: 48px;
  border-radius: 40px;
  border: 0;
  background-color: ${({ variant }) =>
    variant === 'secondary' ? 'green' : 'white'};
  color: ${({ variant }) => (variant === 'secondary' ? 'white' : 'black')};
  padding: 0px 24px;
  align-self: stretch;
  margin: 0.5rem auto;
`
