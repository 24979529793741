import { Button, VStack, Text, Heading, Divider } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { FiArrowLeft } from 'react-icons/fi'

import ReactModal, { Props as ReactModalProps } from 'react-modal'

type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  size: Size
  children?: ReactNode
  EditAllRecords: () => void
  EditOneRecord: () => void
  EditOnlyPreviosRecords: () => void
  goBack: () => void
} & ReactModalProps

const contentStyles = () => {
  return {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0px',
  }
}

const getWidthBySize = (size: Size) => {
  const sizes: Record<Size, string> = {
    sm: '27em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }

  return sizes[size]
}

export const ModalEdit = (props: ModalProps) => {
  const {
    isOpen,
    onClose,
    size = 'md',
    children,
    EditAllRecords,
    EditOneRecord,
    EditOnlyPreviosRecords,
    goBack,
    ...reactModalProps
  } = props
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          ...contentStyles(),
          width: getWidthBySize(size),
          padding: '2rem',
        },
        overlay: {
          zIndex: 999999999,
          backgroundColor: '#000000cc',
        },
      }}
      {...reactModalProps}
    >
      <VStack spacing={4}>
        <Heading as="h2" size="md" mb="2" color={'#FCA130'}>
          Editar registro
        </Heading>
        <Divider />
        <Text>
          Foi identificado que esse pagamento possui outros registros associados
          a ele. O que você gostaria de fazer?
        </Text>

        <Button
          onClick={EditAllRecords}
          colorScheme="red"
          w="100%"
          mt="4"
          h="48px"
          borderRadius="40px"
          variant="outline"
        >
          Editar todos registros
        </Button>
        <Button
          onClick={EditOneRecord}
          colorScheme="red"
          w="100%"
          mt="4"
          h="48px"
          borderRadius="40px"
          variant="outline"
        >
          Editar apenas esse registro
        </Button>

        <Button
          onClick={EditOnlyPreviosRecords}
          colorScheme="red"
          w="100%"
          mt="4"
          h="48px"
          borderRadius="40px"
          variant="outline"
        >
          Editar esse e os registros posteriores
        </Button>
        <Button onClick={goBack} variant="ghost" leftIcon={<FiArrowLeft />}>
          Voltar
        </Button>
      </VStack>
      {children}
    </ReactModal>
  )
}
