type CompoundInterestProps = {
  initialPayment: number
  monthPayment: number
  monthsDuration: number
  monthlyInterestRate: number
}

export function compoundInterest({
  initialPayment: ip,
  monthsDuration: md,
  monthPayment: mp,
  monthlyInterestRate: ir,
}: CompoundInterestProps) {
  const monthResult: Record<
    number,
    {
      initialValue: string
      returns: string
      monthPayment: string
      total: string
    }
  > = {}

  for (let m = 0; m < md; m++) {
    if (m === 0) {
      const returns = ip * ir

      monthResult[m] = {
        initialValue: ip.toFixed(2),
        returns: returns.toFixed(2),
        total: (ip + returns + mp).toFixed(2),
        monthPayment: mp.toFixed(2),
      }

      continue
    }

    const { total } = monthResult[m - 1]

    const returns = Number(total) * ir

    monthResult[m] = {
      initialValue: total,
      monthPayment: mp.toFixed(2),
      returns: returns.toFixed(2),
      total: (Number(total) + returns + mp).toFixed(2),
    }
  }

  return monthResult
}
