import styled, { css } from 'styled-components'

export const SummaryLine = styled.div``

export const SummaryLineHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  margin-bottom: 16px;
`

export const Line = styled.p`
  text-transform: capitalize;
  font-size: 14px;
  opacity: 0.6;
`

export const ValueBox = styled.div`
  display: flex;
  flex-direction: column;
`

export const Value = styled.p<{ isHeader?: boolean }>`
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;

  ${({ isHeader }) =>
    isHeader &&
    css`
      font-size: 18px;
      color: rgba(0, 0, 0, 0.96);
      font-weight: bolder;
    `}
`

export const Percent = styled.p`
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  text-align: right;
`
