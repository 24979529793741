import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  min-height: 100vh;
  min-width: 100vw;

  background-color: black;
  background-position: top;
`

const fadeIn = keyframes`
  from {
    opacity: 0.3;
    transform: translateY(-5%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const BackgroundImage = styled.img`
  max-width: 90%;
  animation: ${fadeIn} 1.4s;
`

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  margin-bottom: 4px;
`

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 56px;

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    color: #ffffff;
    margin-bottom: 16px;

    a {
      font-weight: 600;
    }
  }

  button {
    width: 256px;
    height: 41px;
    left: 59px;
    top: 595px;

    background: #ffffff;
    border-radius: 100px;
    border: 0;
  }
`

export const FooterText = styled.p`
  display: inline;

  margin-top: 56px;
  padding-bottom: 24px;

  max-width: 80vw;

  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: center;

  color: #ffffff;

  span {
    font-weight: 600;
  }
`
