import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useAuth } from 'contexts/auth'

import Main from 'components/Main'
import Header from 'components/Header'
import Button from 'components/Button'
import NavbarBottom from 'components/NavbarBottom'

const Account: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { signOut } = useAuth()
  const { push } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const askForHelp = useCallback(() => {
    Swal.fire(
      'Aviso',
      'Ainda estamos implementando essa função, volte daqui a pouco!',
      'info'
    )
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <React.Fragment>
      <Header title="Ferramentas" />
      <Main bgColor="dark">
        <Button onClick={signOut}>Sair</Button>
        <br />
        <br />
        <Button
          style={{
            backgroundColor: '#22ff41',
          }}
          onClick={askForHelp}
        >
          Pedir ajuda
        </Button>
        <br />
        <br />
        <Button
          style={{
            backgroundColor: '#22ff41',
          }}
          onClick={() => push('/tools/interest')}
        >
          Calculadora de Juros
        </Button>
      </Main>
      <NavbarBottom />
    </React.Fragment>
  )
}

export default Account
