import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Intro from 'pages/public/Intro'
import LogIn from 'pages/public/LogIn'
import SignUp from 'pages/public/SignUp'
import ResetPassword from 'pages/public/ResetPassword'
import ForgotPassword from 'pages/public/ForgotPassword'

import Home from 'pages/private/Home'
import Account from 'pages/private/Account'

import Expenses from 'pages/private/Transaction/Expense/List'
import ExpensesNew from 'pages/private/Transaction/Expense/New'
import ExpensesShow from 'pages/private/Transaction/Expense/Show'

import Revenues from 'pages/private/Transaction/Revenue/List'
import RevenuesNew from 'pages/private/Transaction/Revenue/New'
import RevenuesShow from 'pages/private/Transaction/Revenue/Show'

import Investments from 'pages/private/Transaction/Investment/List'
import InvestmentsNew from 'pages/private/Transaction/Investment/New'
import InvestmentsShow from 'pages/private/Transaction/Investment/Show'

import ReportSummary from 'pages/private/Report/Summary'

import ChartsExpenses from 'pages/private/Report/Chart/Expenses'
import ChartsRevenues from 'pages/private/Report/Chart/Revenues'
import ChartsInvestments from 'pages/private/Report/Chart/Investments'

import DreamsNew from 'pages/private/Tools/Dreams/New'
import DreamsList from 'pages/private/Tools/Dreams/List'
import CompoundInterest from 'pages/private/Tools/CompoundInterest'
import StockAnalysisNew from 'pages/private/Tools/StockAnalysis/New'
import StockAnalysisList from 'pages/private/Tools/StockAnalysis/List'
import Independence from 'pages/private/Tools/Independence'

import ReserveTarget from 'pages/private/ReserveTarget'

import Route from './Route'
import CalculatorLCIxLCA from 'pages/private/Tools/Calculator'

export const App = () => (
  <Switch>
    <Route path="/" exact component={Intro} isPrivate={false} />
    <Route path="/login" exact component={LogIn} isPrivate={false} />
    <Route path="/signup" exact component={SignUp} isPrivate={false} />
    <Route
      path="/forgot_password"
      exact
      component={ForgotPassword}
      isPrivate={false}
    />
    <Route
      path="/reset_password/:token"
      exact
      component={ResetPassword}
      isPrivate={false}
    />

    <Route path="/home" exact component={Home} />
    <Route path="/account" exact component={Account} />

    <Route path="/revenues" exact component={Revenues} />
    <Route path="/revenues/new" exact component={RevenuesNew} />
    <Route path="/revenues/:id" exact component={RevenuesShow} />

    <Route path="/expenses" exact component={Expenses} />
    <Route path="/expenses/new" exact component={ExpensesNew} />
    <Route path="/expenses/:id" exact component={ExpensesShow} />

    <Route path="/investments" exact component={Investments} />
    <Route path="/investments/new" exact component={InvestmentsNew} />
    <Route path="/investments/:id" exact component={InvestmentsShow} />

    <Route path="/charts/expenses" exact component={ChartsExpenses} />
    <Route path="/charts/revenues" exact component={ChartsRevenues} />
    <Route path="/charts/investments" exact component={ChartsInvestments} />
    <Route path="/tools/LciLcaXCDB" exact component={CalculatorLCIxLCA} />

    <Route
      path="/charts/:transactionTypeSlug/summary"
      exact
      component={ReportSummary}
    />

    <Route path="/tools/interest" exact component={CompoundInterest} />
    <Route path="/tools/dreams" exact component={DreamsList} />
    <Route path="/tools/dreams/new" exact component={DreamsNew} />

    <Route path="/tools/stocks" exact component={StockAnalysisList} />
    <Route path="/tools/stocks/new" exact component={StockAnalysisNew} />

    <Route path="/tools/independence" exact component={Independence} />

    <Route path="/reserve_target" exact component={ReserveTarget} />

    <Redirect to="/" />
  </Switch>
)
