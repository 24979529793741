import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import React from 'react'

type AlertDialogProps = {
  isOpen: boolean
  onClose: (value: any) => void
  leastDestructiveRef?: any
  onClickCancel: (value: any) => void
  onClickOk: (value: any) => any
  refButton?: any
  alertText: string
}

export default function AlertDialogComponent(props: AlertDialogProps) {
  const {
    isOpen,
    onClose,
    refButton,
    leastDestructiveRef,
    onClickCancel,
    onClickOk,
    alertText,
  } = props
  return (
    <AlertDialog
      isCentered
      isOpen={isOpen}
      leastDestructiveRef={leastDestructiveRef}
      onClose={() => onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Remover transação
          </AlertDialogHeader>

          <AlertDialogBody>{alertText}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={refButton} onClick={onClickCancel}>
              Cancelar
            </Button>
            <Button colorScheme="red" onClick={onClickOk} ml={3}>
              Remover
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
