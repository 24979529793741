import React, { forwardRef } from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as CKInput,
  InputProps as CKInputProps,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { FieldError } from 'react-hook-form'

type InputProps = CKInputProps & {
  label: string
  name: string
  error?: FieldError
  isDirty?: boolean
}

const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { label, name, error, isDirty, ...rest },
  ref
) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel textAlign="center" htmlFor={name}>
        {label}
      </FormLabel>
      <InputGroup>
        <CKInput
          variant={'filled'}
          isInvalid={!!error}
          id={name}
          ref={ref}
          name={name}
          {...rest}
        />
        <InputRightElement>
          {!!isDirty && !error && <CheckIcon color="green.500" />}
        </InputRightElement>
      </InputGroup>

      {!!error && (
        <FormErrorMessage colorScheme="red">{error.message}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default forwardRef(Input)
