import {
  Box,
  Button,
  Heading,
  Text,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Divider,
} from '@chakra-ui/react'
import Header from 'components/Header'
import Private from 'layouts/Private'
import React, { useEffect, useMemo } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
import { format } from 'utils/currency'
import { Objective } from '../New'
import { BiTrash } from 'react-icons/bi'

type DreamCardProps = Objective & {
  removeHandler: () => void
}

const DreamCard = ({
  targetName,
  monthlyAmount,
  periodInMonths,
  targetAmount,
  removeHandler,
}: DreamCardProps) => (
  <Stat
    position="relative"
    border="1px solid #fafafa"
    shadow="md"
    borderRadius={8}
    px={3}
    pt={2}
    my={3}
  >
    <StatLabel>{targetName}</StatLabel>
    <StatNumber>{format(monthlyAmount)} / mês</StatNumber>
    <StatHelpText>
      {periodInMonths} meses - {targetAmount}
    </StatHelpText>

    <Button
      onClick={removeHandler}
      colorScheme="red"
      position="absolute"
      top={2}
      right={2}
    >
      <BiTrash />
    </Button>
  </Stat>
)

const Dreams: React.FC = () => {
  const [dreams, setDreams] = React.useState<Objective[]>([])

  useEffect(() => {
    const localStorageItems = localStorage.getItem('@fd:dreams')

    const dreams = localStorageItems
      ? (JSON.parse(localStorageItems) as Objective[])
      : []

    setDreams(dreams)
  }, [])

  const monthltTotalAmount = useMemo(() => {
    if (!dreams.length) return 0

    return dreams.reduce((acc, dream) => acc + dream.monthlyAmount, 0)
  }, [dreams])

  const onRemoveDream = (dreamUuid: string) => {
    const localStorageItems = localStorage.getItem('@fd:dreams')

    const dreams = localStorageItems
      ? (JSON.parse(localStorageItems) as Objective[])
      : []

    const filteredDrams = dreams.filter((dream) => dream.uuid !== dreamUuid)
    setDreams(filteredDrams)
    localStorage.setItem('@fd:dreams', JSON.stringify(filteredDrams))
  }

  return (
    <Private>
      <Header title="Meus objetivos" />
      <Box h="72vh" p="2rem" pb="106px" overflowY="auto">
        {!dreams.length ? (
          <>
            <Heading mb={2}>
              Você ainda não possui objetivos cadastrados
            </Heading>
            <Text fontSize={24}>
              Crie o seu primeiro clicando no botão verde
            </Text>
          </>
        ) : (
          <>
            <Heading mb={2}>Total a investir mensalmente</Heading>
            <Text fontSize={24}>{format(monthltTotalAmount)}</Text>

            <Divider mt={4} mb={4} />

            {dreams.map((dream) => (
              <DreamCard
                removeHandler={() => onRemoveDream(dream.uuid)}
                key={dream.uuid}
                {...dream}
              />
            ))}
          </>
        )}

        <Button
          as={Link}
          colorScheme="green"
          position="fixed"
          bottom="116px"
          right="24px"
          size="lg"
          w="56px"
          h="56px"
          borderRadius="28px"
          to="/tools/dreams/new"
          shadow="xl"
        >
          <AddIcon />
        </Button>
      </Box>
    </Private>
  )
}

export default Dreams
