import React from 'react'

import { AuthContextProvider } from './auth'
import { InterceptorProvider } from './interceptor'
import { SubCategoriesProvider } from './sub-categories'

export const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthContextProvider>
      <InterceptorProvider>
        <SubCategoriesProvider>{children}</SubCategoriesProvider>
      </InterceptorProvider>
    </AuthContextProvider>
  )
}
