type pmtProps = {
  ir: number
  np: number
  pv: number
  fv: number
}

export function pmt({ ir, np, pv, fv }: pmtProps) {
  /*
  ir - interest rate per month
  np - number of periods (months)
  pv - present value
  fv - future value (residual value)
  */

  const _fv = fv - pv
  const _ir = ir / 100
  const _ir_plus_one = _ir + 1
  const _ir_plus_one_pow_np = Math.pow(_ir_plus_one, np)
  const _ir_plus_one_pow_np_minus_one = _ir_plus_one_pow_np - 1

  return (_fv * _ir) / (_ir_plus_one * _ir_plus_one_pow_np_minus_one)
}
