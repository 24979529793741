import styled from 'styled-components'

export const TypeSelectorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 12px 0 32px;

  padding-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`
