import React, { useCallback, useEffect, useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import Chart from 'react-apexcharts'
import InputPercentage from '../../../../components/Atoms/InputPercentage'
import {
  Box,
  Button,
  Divider,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'

import Main from 'components/Main'
import Header from 'components/Header'
import { Loading } from 'components/Loading'

import { FiArrowLeft } from 'react-icons/fi'
import InputCurrency from 'components/Atoms/InputCurrency'
import Private from 'layouts/Private'
import axios from 'axios'

type FormInputs = {
  initialValue: string
  selicPercentage: string
  interestLcaLciRate: string
  interestCdbRate: string
}

type ParsedFormInputs = {
  initialValue: number
  selicPercentage: number
  interestLcaLciRate: number
  interestCdbRate: number
}

type getCDIValues = [
  {
    data: string
    valor: string
  }
]

const CalculatorLCIxLCA: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [isLoading, setIsLoading] = useState(false)
  const [hasResult, setHasResult] = useState(false)
  const [getCDIValues, setGetCDIValues] = useState<string>('')
  const [valuesCDB, setValuesCDB] = useState<{
    cdbUntil6Months: {
      cdbPer6Months: number
      totalCDBper6Months: number
      IRUntil6Months: number
      TotalCDBper6MonthsWithouthIR: number
    }
    Cdb6MonthsUntil1Year: {
      cdbPer1yar: number
      totalCDBper1year: number
      IRUntil1Year: number
      TotalCDBper1yearWithouthIR: number
    }
    cdb1YearUntil2Years: {
      cdbPer2years: number
      totalCDBper2years: number
      IRUntil2Years: number
      TotalCDBper2yearsWithouthIR: number
    }
    cdb1YearUntil3Years: {
      cdbPer3years: number
      totalCDBper3years: number
      IRUntil3Years: number
      TotalCDBper3yearsWithouthIR: number
    }
  }>({
    cdbUntil6Months: {
      cdbPer6Months: 0,
      totalCDBper6Months: 0,
      IRUntil6Months: 0,
      TotalCDBper6MonthsWithouthIR: 0,
    },
    Cdb6MonthsUntil1Year: {
      cdbPer1yar: 0,
      totalCDBper1year: 0,
      IRUntil1Year: 0,
      TotalCDBper1yearWithouthIR: 0,
    },
    cdb1YearUntil2Years: {
      cdbPer2years: 0,

      totalCDBper2years: 0,
      IRUntil2Years: 0,
      TotalCDBper2yearsWithouthIR: 0,
    },
    cdb1YearUntil3Years: {
      cdbPer3years: 0,
      totalCDBper3years: 0,
      IRUntil3Years: 0,
      TotalCDBper3yearsWithouthIR: 0,
    },
  })
  const [valuesLciLca, setValuesLciLca] = useState<{
    lciLcaUntil6Months: {
      lciLcaPer6Months: number
      totalLciLcaper6Months: number
    }
    lciLca6MonthsUntil1Year: {
      lciLcaPer1Year: number
      totalLciLcaPer1Year: number
    }
    lciLca1YearUntil2Years: {
      lciLcaPer2Years: number
      totalLciLcaPer2Years: number
    }
    lciLca1YearUntil3Years: {
      lciLcaPer3Years: number
      totalLciLcaPer3Years: number
    }
  }>({
    lciLcaUntil6Months: {
      lciLcaPer6Months: 0,
      totalLciLcaper6Months: 0,
    },
    lciLca6MonthsUntil1Year: {
      lciLcaPer1Year: 0,
      totalLciLcaPer1Year: 0,
    },
    lciLca1YearUntil2Years: {
      lciLcaPer2Years: 0,
      totalLciLcaPer2Years: 0,
    },
    lciLca1YearUntil3Years: {
      lciLcaPer3Years: 0,
      totalLciLcaPer3Years: 0,
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  /* 12	Taxa de juros - CDI	% a.d.	D	06/03/1986	27/07/2022	Cetip	N	Visualizar metadados
  4389	Taxa de juros - CDI anualizada base 252	% a.a.	D	06/03/1986	27/07/2022	BCB-Demab	N	Visualizar metadados
  4391	Taxa de juros - CDI acumulada no mês	% a.m.	M	31/07/1986	jul/2022	BCB-Demab	N	Visualizar metadados
  4392	Taxa de juros - CDI acumulada no mês anualizada base 252	% a.a.	M	31/07/1986	jul/2022	BCB-Demab	N	Visualizar metadados */
  /* http://api.bcb.gov.br/dados/serie/bcdata.sgs.4392/dados?formato=json&dataInicial=02/07/2022&dataFinal=02/07/2022 */
  useEffect(() => {
    const getCDI = async () => {
      const date = new Date()
      const response = await axios
        .get<getCDIValues>(
          `https://api.bcb.gov.br/dados/serie/bcdata.sgs.4392/dados?formato=json&dataInicial=${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}&dataFinal=${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`
        )
        .then((response) => {
          setGetCDIValues(response.data[0].valor)
        })
        .catch((error) => {
          console.log(error)
        })
      return response
    }
    getCDI()

    /*     const IntCdi = parseFloat(getCDIValues)
    const [[integer, decimal]] = getCDIValues.split('.') */

    reset({
      selicPercentage: getCDIValues,
    })
  }, [reset, getCDIValues, setValue])

  const parseCurrency = useCallback((value: string) => {
    const valueWithoutPrefix = value.replace('R$', '')
    const [integer, decimal] = valueWithoutPrefix.split(',')
    const integerWithoutDots = integer.replace(/\./g, '')
    const beforeParseValue = `${integerWithoutDots}.${decimal}`
    const float = parseFloat(beforeParseValue)

    return float
  }, [])

  const parsePercentage = useCallback((value: string) => {
    const valueWithoutPrefix = value.replace(/\s*/, '')
    const valueWithoutPercentage = valueWithoutPrefix.replace('%', '')
    const [integer, decimal] = valueWithoutPercentage.split(',')
    const float = parseFloat(`${integer}.${decimal}`)
    /* const percentage = float / 100 */

    return float
  }, [])

  const parseFormValues = useCallback(
    (formValues: FormInputs): ParsedFormInputs => {
      const {
        initialValue,
        interestLcaLciRate,
        interestCdbRate,
        selicPercentage,
      } = formValues

      return {
        initialValue: parseCurrency(initialValue),
        interestLcaLciRate: parsePercentage(interestLcaLciRate),
        interestCdbRate: parsePercentage(interestCdbRate),
        selicPercentage: parsePercentage(selicPercentage),
      }
    },
    [parseCurrency, parsePercentage]
  )

  const onSubmit: SubmitHandler<FormInputs> = useCallback(
    (formData) => {
      const parsedFormData = parseFormValues(formData)

      setIsLoading(true)

      const {
        initialValue,
        interestLcaLciRate,
        interestCdbRate,
        selicPercentage,
      } = parsedFormData

      const calcCDB = (initialValue: number, interestCdbRate: number) => {
        const cdbAnual =
          initialValue * ((interestCdbRate / 100) * (+selicPercentage / 100))

        // cdb per 6 months
        const cdbPer6Months = cdbAnual / 2
        const totalCDBper6Months = initialValue + cdbPer6Months
        const IRUntil6Months = cdbPer6Months * 0.225
        const TotalCDBper6MonthsWithouthIR = totalCDBper6Months - IRUntil6Months

        // cdb 6 months to 1 year
        const totalCDBper1year = initialValue + cdbAnual
        const IRUntil1Year = cdbAnual * 0.2
        const TotalCDBper1yearWithouthIR = totalCDBper1year - IRUntil1Year

        // cdb 1 year to 2 years
        const totalCDBper2years =
          initialValue *
          Math.pow(1 + (interestCdbRate / 100) * (+selicPercentage / 100), 2)
        const cdbPer2years = totalCDBper2years - initialValue
        const IRUntil2Years = cdbPer2years * 0.175
        const TotalCDBper2yearsWithouthIR = totalCDBper2years - IRUntil2Years

        // cdb 2 years to 3 years
        const totalCDBper3years =
          initialValue *
          Math.pow(1 + (interestCdbRate / 100) * (+selicPercentage / 100), 3)
        const cdbPer3years = totalCDBper3years - initialValue
        const IRUntil3Years = cdbPer3years * 0.15
        const TotalCDBper3yearsWithouthIR = totalCDBper3years - IRUntil3Years

        return {
          cdbUntil6Months: {
            cdbPer6Months: cdbPer6Months,
            totalCDBper6Months: totalCDBper6Months,
            IRUntil6Months: IRUntil6Months,
            TotalCDBper6MonthsWithouthIR: TotalCDBper6MonthsWithouthIR,
          },
          Cdb6MonthsUntil1Year: {
            cdbPer1yar: cdbAnual,
            totalCDBper1year: totalCDBper1year,
            IRUntil1Year: IRUntil1Year,
            TotalCDBper1yearWithouthIR: TotalCDBper1yearWithouthIR,
          },
          cdb1YearUntil2Years: {
            cdbPer2years: cdbPer2years,
            totalCDBper2years: totalCDBper2years,
            IRUntil2Years: IRUntil2Years,
            TotalCDBper2yearsWithouthIR: TotalCDBper2yearsWithouthIR,
          },

          cdb1YearUntil3Years: {
            cdbPer3years: cdbPer3years,
            totalCDBper3years: totalCDBper3years,
            IRUntil3Years: IRUntil3Years,
            TotalCDBper3yearsWithouthIR: TotalCDBper3yearsWithouthIR,
          },
        }
      }

      const calcLcaLci = (initialValue: number, interestLcaLciRate: number) => {
        const lciLcaAnual =
          initialValue * ((interestLcaLciRate / 100) * (+selicPercentage / 100))

        // lci/lca per 6 months
        const lciLcaPerSixMonths = lciLcaAnual / 2
        const totalLciLcaper6Months = initialValue + lciLcaPerSixMonths

        // lci/lca 6 months to 1 year
        const totalLciLcaPer1Year = initialValue + lciLcaAnual
        const lciLcaPer1Year = totalLciLcaPer1Year - initialValue

        // lci/lca 1 year to 2 years
        const totalLciLcaPer2Years =
          initialValue *
          Math.pow(1 + (interestLcaLciRate / 100) * (+selicPercentage / 100), 2)
        const lciLcaPer2Years = totalLciLcaPer2Years - initialValue

        // lci/lca 2 years to 3 years
        const totalLciLcaPer3Years =
          initialValue *
          Math.pow(1 + (interestLcaLciRate / 100) * (+selicPercentage / 100), 3)
        const lciLcaPer3Years = totalLciLcaPer3Years - initialValue

        return {
          lciLcaUntil6Months: {
            lciLcaPer6Months: lciLcaPerSixMonths,
            totalLciLcaper6Months: totalLciLcaper6Months,
          },
          lciLca6MonthsUntil1Year: {
            lciLcaPer1Year: lciLcaPer1Year,
            totalLciLcaPer1Year: totalLciLcaPer1Year,
          },
          lciLca1YearUntil2Years: {
            lciLcaPer2Years: lciLcaPer2Years,
            totalLciLcaPer2Years: totalLciLcaPer2Years,
          },
          lciLca1YearUntil3Years: {
            lciLcaPer3Years: lciLcaPer3Years,
            totalLciLcaPer3Years: totalLciLcaPer3Years,
          },
        }
      }
      const resultCDB = calcCDB(initialValue, interestCdbRate)
      const resultLcaLci = calcLcaLci(initialValue, interestLcaLciRate)
      setValuesCDB(resultCDB)
      setValuesLciLca(resultLcaLci)
      setTimeout(() => {
        setIsLoading(false)
        setHasResult(true)
      }, 800)
    },
    [parseFormValues]
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Private>
      <Header
        label="Balanço"
        value={0}
        isPositive
        title={`Calculadora LCI/LCA x CDB`}
      />
      <Main>
        {!!isLoading && <Loading />}

        {!isLoading && !hasResult && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={8}>
              <Box w="100%">
                <InputCurrency
                  label="Valor"
                  placeholder="Exemplo: 10.000"
                  error={errors.initialValue}
                  isDirty={!!dirtyFields.initialValue}
                  {...register('initialValue', {
                    required: true,
                  })}
                />
              </Box>
              <Box w="100%">
                <Controller
                  name="selicPercentage"
                  control={control}
                  render={({ field: { onChange, onBlur, ref } }) => (
                    <InputPercentage
                      label="CDI Atual (a.a)"
                      placeholder={`Exemplo: ${getCDIValues.replace(
                        '.',
                        ','
                      )}%`}
                      error={errors.selicPercentage}
                      isDirty={!!dirtyFields.selicPercentage}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      name="selicPercentage"
                      hasTooltip={true}
                      tooltipLabel={`O CDI atual é composto pela taxa SELIC subtraído de 0,1.`}
                      /* defaultValue={getCDIValues} */
                    />
                  )}
                />
              </Box>

              <Box w="100%">
                <Controller
                  name="interestLcaLciRate"
                  control={control}
                  render={({ field: { onChange, onBlur, ref } }) => (
                    <InputPercentage
                      label="Porcentagem do CDI - LCA/LCI (a.a.)"
                      placeholder="Exemplo: 88,00%"
                      error={errors.interestLcaLciRate}
                      isDirty={!!dirtyFields.interestLcaLciRate}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      name="interestLcaLciRate"
                      hasTooltip={true}
                      tooltipLabel="Escolha o percentual desejado do CDI - LCA/LCI."
                    />
                  )}
                />
              </Box>

              <Box w="100%">
                <Controller
                  name="interestCdbRate"
                  control={control}
                  render={({ field: { onChange, onBlur, ref } }) => (
                    <InputPercentage
                      label="Porcentagem do CDI - CDB (a.a.)"
                      placeholder="Exemplo: 100,00%"
                      error={errors.interestCdbRate}
                      isDirty={!!dirtyFields.interestCdbRate}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      name="interestCdbRate"
                      hasTooltip={true}
                      tooltipLabel="Escolha o percentual desejado do CDI - CDB."
                    />
                  )}
                />
              </Box>
            </VStack>

            <VStack spacing={4} w="100%">
              <Button
                type="submit"
                h="48px"
                borderRadius="40px"
                colorScheme="green"
                mt="8"
                w="100%"
              >
                Calcular
              </Button>

              <Button
                type="button"
                variant="outline"
                h="48px"
                borderRadius="40px"
                mt="8"
                w="100%"
                colorScheme="red"
                onClick={() => reset()}
              >
                Limpar formulário
              </Button>
            </VStack>
          </form>
        )}

        {!!hasResult && (
          <Box>
            <Button
              leftIcon={<FiArrowLeft />}
              onClick={() => setHasResult(false)}
              mb="12"
            >
              Refazer
            </Button>

            {/* <Divider mb={4} /> */}

            {/* <VStack w="100%" alignItems="flex-start" spacing={4} mb={4}>
              <Heading>Resultado:</Heading>
              <Heading size="md" whiteSpace={'break-spaces'}>
                Valor final do CDB
              </Heading>
              <Heading
                size="md"
                whiteSpace={'break-spaces'}
                fontWeight={'normal'}
              >
                {`0 a 180 dias: R$${valuesCDB.cdbUntil6Months.TotalCDBper6MonthsWithouthIR.toFixed(
                  2
                )}
181 a 360 dias: R$${valuesCDB.Cdb6MonthsUntil1Year.TotalCDBper1yearWithouthIR.toFixed(
                  2
                )}
361 a 720: R$${valuesCDB.cdb1YearUntil2Years.TotalCDBper2yearsWithouthIR.toFixed(
                  2
                )}
721 dias a 1080: R$${valuesCDB.cdb1YearUntil3Years.TotalCDBper3yearsWithouthIR.toFixed(
                  2
                )}`}
              </Heading>
              <Heading size="sm" opacity={0.4}>
                Valor total rendido <br />
              </Heading>
            </VStack> */}

            {/* <Divider mb={4} /> */}

            <Heading mb={4}>Gráfico</Heading>
            <Chart
              type="bar"
              width={'100%'}
              series={[
                {
                  name: 'CDB',
                  data: [
                    {
                      x: '0 a 180 dias',
                      y: valuesCDB.cdbUntil6Months.TotalCDBper6MonthsWithouthIR,
                    },
                    {
                      x: '181 a 360 dias',
                      y: valuesCDB.Cdb6MonthsUntil1Year
                        .TotalCDBper1yearWithouthIR,
                    },
                    {
                      x: '361 a 720',
                      y: valuesCDB.cdb1YearUntil2Years
                        .TotalCDBper2yearsWithouthIR,
                    },
                    {
                      x: '721 dias a 1080',
                      y: valuesCDB.cdb1YearUntil3Years
                        .TotalCDBper3yearsWithouthIR,
                    },
                  ],
                },
                {
                  name: 'LCA/LCI',
                  data: [
                    {
                      x: '0 a 180 dias',
                      y: valuesLciLca.lciLcaUntil6Months.totalLciLcaper6Months,
                    },
                    {
                      x: '181 a 360 dias',
                      y: valuesLciLca.lciLca6MonthsUntil1Year
                        .totalLciLcaPer1Year,
                    },
                    {
                      x: '361 a 720',
                      y: valuesLciLca.lciLca1YearUntil2Years
                        .totalLciLcaPer2Years,
                    },
                    {
                      x: '721 dias a 1080',
                      y: valuesLciLca.lciLca1YearUntil3Years
                        .totalLciLcaPer3Years,
                    },
                  ],
                },
              ]}
              yaxis={{
                title: {
                  text: 'Valor (R$)',
                },
              }}
              height={350}
              options={{
                chart: {
                  id: 'basic-bar',
                  animations: {
                    enabled: true,
                    speed: 1200,
                  },
                  toolbar: {
                    show: false,
                  },
                },
                xaxis: {
                  labels: {
                    show: true,
                  },
                },
                yaxis: {
                  show: true,
                  decimalsInFloat: 0,
                  title: {
                    text: 'Valor R$',
                    style: {
                      color: '#000',
                    },
                  },
                },
                theme: {
                  palette: 'palette2',
                },
                dataLabels: {
                  enabled: false,
                },
                plotOptions: {
                  bar: {
                    borderRadius: 4,
                    horizontal: false,
                    dataLabels: {
                      position: 'bottom',
                      hideOverflowingLabels: true,
                      orientation: 'vertical',
                    },
                  },
                },
              }}
            />
            {/* <VerticalBarChart
              title="Evolução dos juros"
              categories={Object.keys(result)}
              width="100%"
              colors={['#22ff41']}
              series={[
                {
                  name: 'Evolução Mensal',
                  data: Object.values(result).map(
                    ({ total }) => Number(total) / 1000
                  ),
                },
              ]}
            /> */}

            <Divider mb={4} />

            <Heading mb={4}>Tabela CDB</Heading>

            <Box overflowX="auto" mb={'2rem'}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Período</Th>
                    <Th>Valor em Juros</Th>
                    <Th>IR sobre rentabilidade</Th>
                    <Th>Valor pago em imposto de renda</Th>
                    <Th>Valor total líquido</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>0 a 180 dias</Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesCDB.cdbUntil6Months.cdbPer6Months.toFixed(2)}
                      </Text>
                    </Td>

                    <Td>
                      <Text fontSize={12}>22%</Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesCDB.cdbUntil6Months.IRUntil6Months.toFixed(2)}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesCDB.cdbUntil6Months.TotalCDBper6MonthsWithouthIR.toFixed(
                          2
                        )}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>181 a 360 dias</Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesCDB.Cdb6MonthsUntil1Year.cdbPer1yar.toFixed(2)}
                      </Text>
                    </Td>

                    <Td>
                      <Text fontSize={12}>20%</Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesCDB.Cdb6MonthsUntil1Year.IRUntil1Year.toFixed(2)}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesCDB.Cdb6MonthsUntil1Year.TotalCDBper1yearWithouthIR.toFixed(
                          2
                        )}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>361 a 720</Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesCDB.cdb1YearUntil2Years.cdbPer2years.toFixed(2)}
                      </Text>
                    </Td>

                    <Td>
                      <Text fontSize={12}>17,5%</Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesCDB.cdb1YearUntil2Years.IRUntil2Years.toFixed(2)}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesCDB.cdb1YearUntil2Years.TotalCDBper2yearsWithouthIR.toFixed(
                          2
                        )}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>721 dias a 1080</Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesCDB.cdb1YearUntil3Years.cdbPer3years.toFixed(2)}
                      </Text>
                    </Td>

                    <Td>
                      <Text fontSize={12}>15%</Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesCDB.cdb1YearUntil3Years.IRUntil3Years.toFixed(2)}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesCDB.cdb1YearUntil3Years.TotalCDBper3yearsWithouthIR.toFixed(
                          2
                        )}
                      </Text>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>

            <Heading mb={4}>Tabela LCI/LCA</Heading>

            <Box overflowX="auto">
              <Table>
                <Thead>
                  <Tr>
                    <Th>Período</Th>
                    <Th>Valor em Juros</Th>
                    <Th>IR sobre rentabilidade</Th>
                    <Th>Valor pago em imposto de renda</Th>
                    <Th>Valor total líquido</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>0 a 180 dias</Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesLciLca.lciLcaUntil6Months.lciLcaPer6Months.toFixed(
                          2
                        )}
                      </Text>
                    </Td>

                    <Td>
                      <Text fontSize={12}>Isento</Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>-</Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesLciLca.lciLcaUntil6Months.totalLciLcaper6Months.toFixed(
                          2
                        )}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>181 a 360 dias</Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesLciLca.lciLca6MonthsUntil1Year.lciLcaPer1Year.toFixed(
                          2
                        )}
                      </Text>
                    </Td>

                    <Td>
                      <Text fontSize={12}>Isento</Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>-</Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesLciLca.lciLca6MonthsUntil1Year.totalLciLcaPer1Year.toFixed(
                          2
                        )}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>361 a 720</Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesLciLca.lciLca1YearUntil2Years.lciLcaPer2Years.toFixed(
                          2
                        )}
                      </Text>
                    </Td>

                    <Td>
                      <Text fontSize={12}>Isento</Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>-</Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesLciLca.lciLca1YearUntil2Years.totalLciLcaPer2Years.toFixed(
                          2
                        )}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>721 dias a 1080</Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesLciLca.lciLca1YearUntil3Years.lciLcaPer3Years.toFixed(
                          2
                        )}
                      </Text>
                    </Td>

                    <Td>
                      <Text fontSize={12}>Isento</Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>-</Text>
                    </Td>
                    <Td>
                      <Text fontSize={12}>
                        {valuesLciLca.lciLca1YearUntil3Years.totalLciLcaPer3Years.toFixed(
                          2
                        )}
                      </Text>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        )}
      </Main>
    </Private>
  )
}

export default CalculatorLCIxLCA

/* Desenvolvimento site Fádia de Oliveira
Desenvolvimento site Diego Anselmini
modificação site Iane Miguel Pereira
modificação site Marcelo Peron
adição tag manager / API Fernanda Oliveira Ferraz
modificação site Fadia-Oliveira
hostinger / cloudflare Ana Jullia
modificação site Maysa
desenvolvimento de site Fábio Henrique Pinto de Moraes / forms
desenvolvimento site Thyago Alves Nunes
Conclusão do FormsMCP
transferência domínio / apicount / tagmanager Sanna Roque
desenvolvimento site Diego Anselmini
Modificação site Fádia Oliveira */
