import { Box } from '@chakra-ui/react'
import Header from 'components/Header'
import Private from 'layouts/Private'
import React, { useState } from 'react'

import { Intro } from './components/intro'
import { Discovery } from './components/discovery'
import { Acumulation } from './components/acumulation'
import { Distribution } from './components/distribution'
import { Render } from 'components/Render'

type Steps = 'intro' | 'discovery' | 'acumulation' | 'distribution'

const Independence: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [step, setStep] = React.useState<Steps>('intro')
  const [globalTargetAmount, setGlobalTargetAmount] = useState<number>()
  const [monthlyWithdrawals, setMonthlyWithdrawals] = useState<number>()
  const [globalMonthsToAccomplish, setGlobalMonthsToAccomplish] =
    useState<number>()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const goToIntro = () => setStep('intro')
  const goToDiscovery = () => setStep('discovery')
  const goToAcumulation = () => setStep('acumulation')
  const goTodistribution = () => setStep('distribution')
  const is = (currentStep: Steps) => currentStep === step

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Private>
      <Header title="Independência Financeira" />
      <Box h="72vh" p="2rem" pb="106px" overflowY="auto">
        <Render when={is('intro')}>
          <Intro next={goToDiscovery} />
        </Render>

        <Render when={is('discovery')}>
          <Discovery
            next={goToAcumulation}
            previous={goToIntro}
            setGlobalAmount={setGlobalTargetAmount}
            setMonthlyWithdrawals={setMonthlyWithdrawals}
          />
        </Render>

        <Render when={is('acumulation')}>
          <Acumulation
            next={goTodistribution}
            previous={goToDiscovery}
            targetAmount={globalTargetAmount}
            setGlobalMonthsToAccomplish={setGlobalMonthsToAccomplish}
          />
        </Render>

        <Render when={is('distribution')}>
          <Distribution
            next={goToDiscovery}
            previous={goToAcumulation}
            targetAmount={globalTargetAmount}
            monthlyWithdrawals={monthlyWithdrawals}
            monthsToAccomplish={globalMonthsToAccomplish}
          />
        </Render>
      </Box>
    </Private>
  )
}

export default Independence
