import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100vh;
    color: white;
  }

  body {
    background-color: black;
    font-family: 'Poppins', sans-serif;

    position: relative; // important to keep bottom nav fixed.
  }

  button {
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: unset;
  }

  input[type='submit'] {
    cursor: pointer;
  }

  li {
    list-style: none;
  }

  form {
    display: flex;
    flex-direction: column;

    input, select {
      height: 48px;
      border-radius: 40px;
      border: 0;
      background-color: rgba(0, 0, 0, 0.04);
      padding: 0px 24px;

      &:not(:first-child) {
        /* margin-top: 8px; */
      }

      &[type='submit'] {
        margin-top: 24px;
        background: black;
        color: white;
      }
    }
  }

  /* External Libraries */

  .swal2-styled.swal2-confirm {
    background: #22ff41;
    color: black;
  }
`
