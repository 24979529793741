import React, { forwardRef } from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as CKInput,
  InputGroup,
  InputProps,
  // InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { FieldError } from 'react-hook-form'
import CurrencyInput from 'react-currency-input-field'

type InputCurrencyProps = InputProps & {
  label: string
  name: string
  error?: FieldError
  isDirty?: boolean
}

const InputCurrency: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputCurrencyProps
> = ({ label, name, error, isDirty, ...rest }, ref) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel textAlign="center" htmlFor={name}>
        {label}
      </FormLabel>
      <InputGroup>
        <CKInput
          as={CurrencyInput}
          variant="filled"
          isInvalid={!!error}
          id={name}
          ref={ref}
          name={name}
          decimalScale={2}
          intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          allowNegativeValue={false}
          prefix="R$"
          {...rest}
        />
        <InputRightElement>
          {!!isDirty && !error && <CheckIcon color="green.500" />}
        </InputRightElement>
      </InputGroup>

      {!!error && (
        <FormErrorMessage colorScheme="red">{error.message}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default forwardRef(InputCurrency)
