import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Text,
  List,
  Button,
  Heading,
  ListIcon,
  ListItem,
  OrderedList,
} from '@chakra-ui/react'
import React from 'react'
import { IoMdCheckmarkCircle } from 'react-icons/io'

type IntroProps = {
  next(): void
}

export const Intro: React.FC<IntroProps> = ({ next }) => {
  return (
    <>
      <Heading>Instruções</Heading>

      <Text mt={2} opacity={0.4} fontSize={12}>
        O cálculo da independência financeira é dividido em três etapas:
      </Text>

      <OrderedList>
        <List spacing={4} mt={4}>
          <ListItem>
            <ListIcon as={IoMdCheckmarkCircle} color="green.500" />
            Na primeira etapa você vai{' '}
            <strong>descobrir o &quot;seu número&quot;</strong> da independência
            financeira.
          </ListItem>
          <ListItem>
            <ListIcon as={IoMdCheckmarkCircle} color="green.500" />A segunda
            etapa é a <strong>&quot;Fase de acumulação&quot;</strong>. Essa
            etapa serve para estimar o tempo que você levará para atingir a sua
            meta de acumulação (&quot;Patrimônio Meta&quot;), de acordo com o
            valor que você investir mensalmente e com a taxa de retorno destes
            investimentos.
          </ListItem>
          <ListItem>
            <ListIcon as={IoMdCheckmarkCircle} color="green.500" />A{' '}
            <strong>&quot;Fase de distribuição&quot;</strong>, na terceira
            etapa, serve para estimar o tempo que seu patrimônio irá se
            sustentar de acordo com a sua retirada mensal, na fase de
            distribuição.
          </ListItem>
        </List>
      </OrderedList>

      <Button
        colorScheme="green"
        width="100%"
        mt={8}
        rightIcon={<ArrowForwardIcon />}
        onClick={next}
      >
        Iniciar
      </Button>
    </>
  )
}
