import React, { useCallback, useMemo } from 'react'
import { FiBarChart } from 'react-icons/fi'
import { AiFillHome } from 'react-icons/ai'
import { BiWallet, BiCreditCard } from 'react-icons/bi'
import { FaPiggyBank } from 'react-icons/fa'
import { useLocation, useHistory } from 'react-router-dom'

import { Container } from './styles'

const NavbarBottom: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { pathname } = useLocation()
  const history = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const navigate = useCallback(
    (path: string) => {
      history.push(path)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const navOptions = useMemo(
    () => [
      { path: '/home', icon: AiFillHome },
      { path: '/expenses', icon: BiCreditCard },
      { path: '/revenues', icon: BiWallet },
      { path: '/investments', icon: FaPiggyBank },
      { path: '/charts/investments', icon: FiBarChart },
    ],
    []
  )

  const pathNameMatcher = useMemo(() => {
    return pathname.split('/')[1]
  }, [pathname])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Container>
      <div>
        {navOptions.map(({ path, icon: Icon }) => (
          <button key={path} onClick={() => navigate(path)}>
            <Icon
              color={
                pathNameMatcher.startsWith(path.split('/')[1])
                  ? '#000000'
                  : '#d7d7d7'
              }
              size={28}
            />
          </button>
        ))}
      </div>
    </Container>
  )
}

export default NavbarBottom
