import React, { useCallback, useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useToast,
  VStack,
} from '@chakra-ui/react'

import NavbarBottom from 'components/NavbarBottom'
import Header from 'components/Header'
import Main from 'components/Main'

import { api } from 'services/api'
import { FiArrowLeft } from 'react-icons/fi'
import { useAuth } from 'contexts/auth'
import { CheckIcon } from '@chakra-ui/icons'

type Inputs = {
  amount: number
}

const ReserveTarget: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { user, updateProfile } = useAuth()
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Inputs>()
  const { goBack } = useHistory()
  const toast = useToast({ position: 'top' })

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const onSubmit: SubmitHandler<Inputs> = useCallback(
    (formData) => {
      if (!user) return

      api
        .put(`/users/${user.id}/target`, formData)
        .then(() => {
          updateProfile()

          toast({
            status: 'success',
            title: 'Meta definida com sucesso!',
          })
        })
        .catch((e) => {
          toast({
            status: 'error',
            title: 'Erro ao definir meta',
          })
          console.trace(e)
        })
    },
    [toast, updateProfile, user]
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    setValue('amount', user?.monthly_reserve_percentage_target || 0)
  }, [setValue, user])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <React.Fragment>
      <Header
        label="Receitas"
        value={0}
        isPositive={true}
        title="Registre uma meta"
      />
      <Main>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box w="100%" mt={4}>
            <Text textAlign={'center'} mb="2">
              Meta de investimento mensal (%)
            </Text>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.300"
                fontSize="1.2em"
              >
                %
              </InputLeftElement>
              <Input
                type="number"
                step="0.01"
                variant={'filled'}
                placeholder="Digital a taxa"
                {...register('amount', {
                  valueAsNumber: true,
                  required: true,
                  max: 100,
                  min: 0,
                  onBlur: () => trigger('amount'),
                })}
              />
              <InputRightElement>
                {!errors.amount && <CheckIcon color="green.500" />}
              </InputRightElement>
            </InputGroup>
            <Text color="red" fontSize={12} mt={2}>
              {errors.amount && 'Valor inválido.'}
            </Text>
            <Text color="gray" fontSize={12} mt={2}>
              Registre uma meta percentual de investimento, ou seja, quanto
              porcento dos seus ganhos você pretende investir no mês.
            </Text>
          </Box>

          <VStack spacing={4} w="100%">
            <Button
              type="submit"
              h="48px"
              borderRadius="40px"
              colorScheme="green"
              mt="8"
              w="100%"
            >
              Registrar
            </Button>

            <Button
              type="button"
              h="48px"
              borderRadius="40px"
              colorScheme="gray"
              mt="8"
              variant={'ghost'}
              leftIcon={<FiArrowLeft />}
              w="100%"
              onClick={goBack}
            >
              Voltar
            </Button>
          </VStack>
        </form>
      </Main>
      <NavbarBottom />
    </React.Fragment>
  )
}

export default ReserveTarget
