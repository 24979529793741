import React from 'react'
import Swal from 'sweetalert2'
import { Link, useHistory } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'

import {
  BackgroundImage,
  Container,
  CTAContainer,
  FooterText,
  Form,
} from './styles'

import backgroundImage from './background.png'
import { api } from 'services/api'

type FormInputs = {
  email: string
}

const ForgotPassword: React.FC = () => {
  const { register, handleSubmit } = useForm()
  const { push } = useHistory()

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    api
      .post('/forgot_password', data)
      .then(() => {
        Swal.fire(
          'Sucesso',
          'Você vai receber um e-mail com instruções para redefinir a sua senha.',
          'success'
        )
        push('/')
      })
      .catch((err) => {
        console.trace(err)
        Swal.fire(
          'Aviso',
          'Houve um erro ao processar o seu pedido. Confirme o dados inserido ou tente novamente mais tarde.',
          'error'
        )
      })
  }

  return (
    <Container>
      <BackgroundImage src={backgroundImage} alt="header image" />
      <CTAContainer>
        <p>Solicite uma redefinição de senha</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <input type="text" placeholder="E-mail" {...register('email')} />
          <input type="submit" value="Solicitar" />
        </Form>
        <Link to="/">Voltar</Link>
      </CTAContainer>
      <FooterText>
        Ao continuar, você concorda com os <span>Termos de Serviço</span> e com
        a <span>Política de Privacidade</span> do Finanças Dominadas
      </FooterText>
    </Container>
  )
}

export default ForgotPassword
