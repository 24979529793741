import React, {
  useState,
  createContext,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from 'react'
import Swal from 'sweetalert2'
import { get, trim } from 'lodash'
import { api } from 'services/api'

type SignInPayload = {
  email: string
  password: string
}

type SignInResponse = {
  user: {
    id: string
    name: string
    email: string
    is_admin: boolean
    created_at: string
    updated_at: string
  }
  session: {
    type: string
    token: string
  }
}

type User = {
  id: string
  name: string
  email: string
  is_admin: boolean
  avatar?: string
  monthly_reserve_percentage_target?: number
}

interface AuthContextProps {
  user: User | undefined
  isLogged: boolean
  isLoading: boolean
  signIn: (payload: SignInPayload) => void
  signOut: () => void
  updateProfile: () => void
}

export const AuthContext = createContext({} as AuthContextProps)

const SESSION_STORAGE_KEY = '@app:auth'

export const AuthContextProvider: React.FC = ({ children }) => {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [user, setUser] = useState<User>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const signIn = useCallback(async ({ email, password }: SignInPayload) => {
    setIsLoading(true)
    api
      .post<SignInResponse>('/sessions', {
        email: trim(email).toLowerCase(),
        password,
      })
      .then(
        ({
          data: {
            user,
            session: { token },
          },
        }) => {
          setUser(user)
          localStorage.setItem(
            SESSION_STORAGE_KEY,
            JSON.stringify({ token, user })
          )
          api.defaults.headers.Authorization = `Bearer ${token}`
        }
      )
      .catch((err) => {
        const structuredError = get(err, 'response.data.errors[0].message')
        const messageError = get(err, 'response.data')

        const errorMessage =
          structuredError ??
          messageError ??
          'Houve uma falha no seu login. Tente novamente mais tarde.'

        Swal.fire('Aviso', errorMessage, 'error')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const signOut = useCallback(() => {
    localStorage.removeItem(SESSION_STORAGE_KEY)
    setUser(undefined)
    api.defaults.headers.Authorization = ''
  }, [])

  const updateProfile = useCallback(() => {
    api
      .get<User>('/users/me')
      .then(({ data }) => {
        setUser(data)
      })
      .catch((err) => {
        console.trace(err)
      })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    const result = localStorage.getItem(SESSION_STORAGE_KEY)
    if (!result) return setIsLoading(false)

    const { token, user } = JSON.parse(result) as { user: User; token: string }

    setUser(user)
    api.defaults.headers.Authorization = `Bearer ${token}`

    setIsLoading(false)
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const authContextValues = useMemo(
    () => ({
      user,
      signIn,
      signOut,
      isLoading,
      isLogged: !!user,
      updateProfile,
    }),
    [isLoading, signIn, signOut, updateProfile, user]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <AuthContext.Provider value={authContextValues}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
