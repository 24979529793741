import React from 'react'
import { Link } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useAuth } from 'contexts/auth'

import {
  BackgroundImage,
  Container,
  CTAContainer,
  FooterText,
  Form,
} from './styles'

import backgroundImage from './background.png'

type FormInputs = {
  email: string
  password: string
}

const LogIn: React.FC = () => {
  const { register, handleSubmit } = useForm()
  const { signIn } = useAuth()

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    signIn(data)
  }

  return (
    <Container>
      <BackgroundImage src={backgroundImage} alt="header image" />
      <CTAContainer>
        <p>
          Ainda não é membro?{' '}
          <a
            href="https://financasdominadas.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            Clique aqui
          </a>
        </p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <input type="text" placeholder="E-mail" {...register('email')} />
          <input
            type="password"
            placeholder="Senha"
            {...register('password')}
          />
          <input type="submit" value="Entrar" />
        </Form>
        <Link to="/forgot_password">Esqueci minha senha</Link>
      </CTAContainer>
      <FooterText>
        Ao continuar, você concorda com os <span>Termos de Serviço</span> e com
        a <span>Política de Privacidade</span> do Finanças Dominadas
      </FooterText>
    </Container>
  )
}

export default LogIn
