import React, { forwardRef } from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as CKInput,
  InputProps as CKInputProps,
  InputGroup,
  InputRightElement,
  HStack,
  Stack,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { FieldError } from 'react-hook-form'
import { Tooltip } from '../Tooltip'
import { BsInfoCircleFill } from 'react-icons/bs'
import NumberFormat from 'react-number-format'

type InputProps = CKInputProps & {
  label: string
  name: string
  error?: FieldError
  isDirty?: boolean
  hasTooltip?: boolean
  tooltipLabel?: any
  defaultValue?: any
}

const InputPercentage: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = (
  {
    label,
    name,
    error,
    isDirty,
    hasTooltip,
    tooltipLabel,
    defaultValue,
    ...rest
  },
  ref
) => {
  return (
    <FormControl isInvalid={!!error}>
      <HStack justifyContent={'center'} alignItems={'center'}>
        <Stack>
          <FormLabel mr={'0'} htmlFor={name}>
            {label}
          </FormLabel>
        </Stack>
        <Stack alignItems={'flex-start'}>
          {hasTooltip && (
            <Stack mb={'8px'}>
              <Tooltip label={tooltipLabel}>
                <BsInfoCircleFill color="#ADD8E6" />
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </HStack>
      <InputGroup>
        <CKInput
          as={NumberFormat}
          variant={'filled'}
          isInvalid={!!error}
          id={name}
          ref={ref}
          name={name}
          allowNegativeValue
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          decimalSeparator={','}
          suffix={'%'}
          {...rest}
          defaultValue={defaultValue}
        />
        <InputRightElement>
          {!!isDirty && !error && <CheckIcon color="green.500" />}
        </InputRightElement>
      </InputGroup>

      {!!error && (
        <FormErrorMessage colorScheme="red">{error.message}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default forwardRef(InputPercentage)
