import React, { useEffect, useMemo, useState } from 'react'

import { round, mean } from 'lodash'
import { List } from 'react-feather'

import Main from 'components/Main'
import Header from 'components/Header'
import { Loading } from 'components/Loading'
import { PieChart } from 'components/Chart/Pie'
import { LineChart } from 'components/Chart/Line'
import NavbarBottom from 'components/NavbarBottom'
// import { RadarChart } from 'components/Chart/Radar'
import { VerticalBarChart } from 'components/Chart/VerticalBar'
import { ReportPageSelector } from 'components/ReportPageSelector'

import { api } from 'services/api'

import { MONTH_INDEX } from 'constants/month-index'

import {
  TransactionTypesEnum,
  TransactionTypeSlugs,
  TransactionTypesApexPalettes,
  TransactionTypesColors,
} from '../../types'
import { Flex, SimpleGrid } from '@chakra-ui/react'

type MonthIndexes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

type MonthAmount = {
  month: MonthIndexes
  amount: number
}

type CategoryAmount = {
  category_name: string
  sum_amount: string
}

type SubCategoryAmount = {
  category_name: string
  sum_amount: string
}

type InvestmentsRevenuesAmount = {
  month: MonthIndexes
  revenues_amount: string
  investments_amount: string
}

type InvestmentsPercentage = {
  month: MonthIndexes
  percentage: number
}

const Revenues: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const transactionTypeSlug: TransactionTypeSlugs = 'investments'

  const transactionTypeName = TransactionTypesEnum[transactionTypeSlug]

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [isLoading] = useState(false)
  const [monthAmount, setMonthAmount] = useState<MonthAmount[]>([])
  const [categoryAmount, setCategoryAmount] = useState<CategoryAmount[]>([])
  const [subCategoryAmount, setSubCategoryAmount] = useState<
    SubCategoryAmount[]
  >([])
  const [investmentPercentage, setInvestmentPercetange] = useState<
    InvestmentsPercentage[]
  >([])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    /**
     * Buscar soma de transações agrupadas por mês para um tipo de trnasação.
     */
    api
      .get<MonthAmount[]>('/statistics/by_type_per_month', {
        params: {
          transactionTypeSlug,
        },
      })
      .then(({ data }) => {
        setMonthAmount(
          data.map(({ amount, month }) => ({
            amount: round(amount / 1000, 1),
            month,
          }))
        )
      })
      .catch((e) => {
        console.trace(e)
      })
  }, [transactionTypeSlug])

  useEffect(() => {
    /**
     * Buscar soma de transações agrupadas por categoria para um tipo de transação.
     */
    api
      .get<CategoryAmount[]>('/statistics/by_category_per_type', {
        params: {
          transactionTypeSlug,
        },
      })
      .then(({ data }) => {
        setCategoryAmount(data)
      })
      .catch((e) => {
        console.trace(e)
      })
  }, [transactionTypeSlug])

  useEffect(() => {
    /**
     * Buscar soma de transações agrupadas por sub categoria para um tipo de transação.
     */
    api
      .get<SubCategoryAmount[]>('/statistics/by_sub_category_per_type', {
        params: {
          transactionTypeSlug,
        },
      })
      .then(({ data }) => {
        setSubCategoryAmount(data)
      })
      .catch((e) => {
        console.trace(e)
      })
  }, [transactionTypeSlug])

  useEffect(() => {
    /**
     * Buscar soma de transações agrupadas mês de receitas e investimentos.
     */
    api
      .get<InvestmentsRevenuesAmount[]>(
        '/statistics/investment_over_revenues_per_month'
      )
      .then(({ data }) => {
        setInvestmentPercetange(
          data.map(({ month, investments_amount, revenues_amount }) => ({
            month,
            percentage:
              (Number(investments_amount) / Number(revenues_amount)) * 100,
          }))
        )
      })
      .catch((e) => {
        console.trace(e)
      })
  }, [transactionTypeSlug])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const parsedInvestmentPercentage = useMemo(() => {
    let percentagePerMonth: number[] = []
    let percentageMeanVector: number[] = []
    let month: string[] = []

    if (investmentPercentage.length) {
      percentagePerMonth = investmentPercentage.map(({ percentage: p }) => p)
      const percentageMean = mean(percentagePerMonth)
      percentageMeanVector = investmentPercentage.map(() => percentageMean)
      month = investmentPercentage.map(({ month: m }) =>
        MONTH_INDEX[m].slice(0, 3)
      )
    }

    return {
      percentagePerMonth,
      percentageMeanVector,
      month,
    }
  }, [investmentPercentage])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <React.Fragment>
      <Header
        label="Balanço"
        value={0}
        isPositive
        title={`Acompanhe\nseus ${transactionTypeName}`}
        pagePath="/charts/investments/summary"
        pagePathIcon={List}
      />
      <Main>
        <ReportPageSelector />

        {isLoading ? (
          <Loading />
        ) : (
          <SimpleGrid
            rounded={'lg'}
            /* bg={'gray.50'} */
            columns={{ sm: 1, md: 2 }}
            spacingX={4}
            spacingY={4}
          >
            <Flex
              h={'280px'}
              alignItems={'center'}
              justifyContent={'center'}
              border={'1px solid'}
              borderColor={'gray.200'}
              rounded={'lg'}
              boxShadow={'md'}
            >
              <VerticalBarChart
                title={`Evolução mensal de ${transactionTypeName}`}
                categories={monthAmount.map(({ month }) =>
                  MONTH_INDEX[month].slice(0, 3)
                )}
                width="100%"
                height="85%"
                series={[
                  {
                    name: 'Evolução Mensal',
                    data: monthAmount.map(({ amount }) => amount),
                  },
                ]}
                colors={[TransactionTypesColors[transactionTypeSlug]]}
              />
            </Flex>

            <Flex
              h={'280px'}
              alignItems={'center'}
              justifyContent={'center'}
              border={'1px solid'}
              borderColor={'gray.200'}
              rounded={'lg'}
              boxShadow={'md'}
            >
              <PieChart
                title="Distribuição por Categoria (top 6)"
                palette={TransactionTypesApexPalettes[transactionTypeSlug]}
                width="100%"
                height="95%"
                series={categoryAmount
                  .slice(0, 6)
                  .map(({ sum_amount: sa }) => Number(sa))}
                labels={categoryAmount
                  .slice(0, 6)
                  .map(({ category_name: cn }) => cn)}
              />
            </Flex>

            <Flex
              h={'280px'}
              alignItems={'center'}
              justifyContent={'center'}
              border={'1px solid'}
              borderColor={'gray.200'}
              rounded={'lg'}
              boxShadow={'md'}
            >
              <PieChart
                title="Distribuição por Sub Categoria (top 6)"
                palette={TransactionTypesApexPalettes[transactionTypeSlug]}
                width="100%"
                height="95%"
                series={subCategoryAmount
                  .slice(0, 6)
                  .map(({ sum_amount: sa }) => Number(sa))}
                labels={subCategoryAmount
                  .slice(0, 6)
                  .map(({ category_name: cn }) => cn)}
              />
            </Flex>

            <Flex
              h={'280px'}
              alignItems={'center'}
              justifyContent={'center'}
              border={'1px solid'}
              borderColor={'gray.200'}
              rounded={'lg'}
              boxShadow={'md'}
            >
              <LineChart
                title="Evolução % investido sobre renda"
                categories={parsedInvestmentPercentage.month}
                width="100%"
                height="85%"
                series={[
                  {
                    name: 'Evolução',
                    data: parsedInvestmentPercentage.percentagePerMonth,
                  },
                  {
                    name: 'Média.',
                    data: parsedInvestmentPercentage.percentageMeanVector,
                  },
                ]}
                colors={[
                  TransactionTypesColors[transactionTypeSlug],
                  TransactionTypesColors[transactionTypeSlug] + '40',
                ]}
              />
            </Flex>

            {/* <Card>
              <RadarChart
                title={`Radar de ${transactionTypeName} [DUMMY]`}
                palette={TransactionTypesApexPalettes[transactionTypeSlug]}
                series={[
                  {
                    name: 'Ganhos',
                    data: [45, 52, 38, 24, 33, 10],
                  },
                  {
                    name: 'Gastos',
                    data: [40, 54, 59, 32, 20, 16],
                  },
                ]}
                labels={['abs', 'mai', 'jun', 'jul', 'ago', 'set']}
              />
            </Card> */}
          </SimpleGrid>
        )}
      </Main>
      <NavbarBottom />
    </React.Fragment>
  )
}

export default Revenues
