import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Text,
  Button,
  Heading,
  HStack,
  Box,
  VStack,
  Flex,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import InputCurrency from 'components/Atoms/InputCurrency'
import React, { useMemo, useState } from 'react'
import { parseCurrency } from 'utils/parse-currency-to-float'
import { parseDecimal } from 'utils/parse-decimal'
import { format } from 'utils/currency'
import InputInteger from 'components/Atoms/InputInteger'

type DiscoveryProps = {
  setMonthlyWithdrawals(amount: number): void
  setGlobalAmount(amount: number): void
  previous(): void
  next(): void
}

type FormInputs = {
  targetMonthlyWithdrawAmount: string
  monthlyReturn: string
}

export const Discovery: React.FC<DiscoveryProps> = ({
  next,
  previous,
  setGlobalAmount,
  setMonthlyWithdrawals,
}) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    register,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm<FormInputs>()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [targetAmount, setTargetAmount] = useState<number>()

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const hasTargetAmountSet = useMemo(() => !!targetAmount, [targetAmount])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const calculateTargetAmount = (
    floatTargetAmount: number,
    floatReturn: number
  ) => {
    return floatTargetAmount / (Math.pow(1 + floatReturn, 1 / 12) - 1)
  }

  const submitHandler = handleSubmit((data) => {
    const monthlyWithdrawal = parseCurrency(data.targetMonthlyWithdrawAmount)
    const monthlyReturn = parseDecimal(data.monthlyReturn) / 100

    const targetAmount = calculateTargetAmount(monthlyWithdrawal, monthlyReturn)

    setMonthlyWithdrawals(monthlyWithdrawal)

    setTargetAmount(targetAmount)
    setGlobalAmount(targetAmount)
  })

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <>
      {!hasTargetAmountSet && (
        <>
          <Heading>Seu número</Heading>
          <Text mt={2} opacity={0.4} fontSize={12}>
            Descubra o &quot;seu número&quot; da independência financeira.
          </Text>

          <Box as="form" onSubmit={submitHandler} my={4}>
            <VStack spacing={4}>
              <InputCurrency
                label="Renda Mensal Desejada"
                placeholder="Exemplo: R$ 40.000,00"
                error={errors.targetMonthlyWithdrawAmount}
                isDirty={!!dirtyFields.targetMonthlyWithdrawAmount}
                {...register('targetMonthlyWithdrawAmount', {
                  required: true,
                })}
              />

              <InputInteger
                label="Taxa Anual de Retorno Acima da Inflação (em %)"
                placeholder="Exemplo: 5,25"
                error={errors.monthlyReturn}
                isDirty={!!dirtyFields.monthlyReturn}
                {...register('monthlyReturn', {
                  required: true,
                })}
              />
            </VStack>

            <Button type="submit" colorScheme="green" mt={4}>
              Calcular
            </Button>
          </Box>
        </>
      )}

      {!!targetAmount && (
        <Flex flexDirection="column" height="100%">
          <Heading as="h3">O seu número é</Heading>
          <Text mt={2} opacity={0.8} fontSize={24}>
            {format(targetAmount)}
          </Text>
          <Text opacity={0.4} mt={2} fontSize={13}>
            Esse é o número para a sua independência financeira :D
          </Text>

          <HStack mt="auto">
            <Button
              colorScheme="gray"
              width="100%"
              leftIcon={<ArrowBackIcon />}
              onClick={previous}
            >
              Voltar
            </Button>

            <Button
              colorScheme="green"
              width="100%"
              rightIcon={<ArrowForwardIcon />}
              onClick={next}
            >
              Avançar
            </Button>
          </HStack>
        </Flex>
      )}
    </>
  )
}
