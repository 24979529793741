import styled from 'styled-components'

import { MainProps } from './index'

export const Container = styled.main<Pick<MainProps, 'bgColor'>>`
  height: calc(72vh); // header takes 28vh
  overflow: auto;

  background: ${({ bgColor }) => (bgColor === 'dark' ? 'black' : 'white')};
  color: ${({ bgColor }) => (bgColor === 'dark' ? 'white' : 'black')};

  padding: 2rem 2rem;
  padding-bottom: 96px; // required because of NavbarBottom height (80px)

  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
`
