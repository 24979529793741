import React, { useState } from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  useToast,
  VStack,
  Input as ChakraInput,
  IconButton,
  HStack,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import NavbarBottom from 'components/NavbarBottom'
import Header from 'components/Header'
import Main from 'components/Main'

import { useSubCategories } from 'contexts/sub-categories'

import { api } from 'services/api'
import { FiArrowLeft } from 'react-icons/fi'
import InputCurrency from 'components/Atoms/InputCurrency'
import Input from 'components/Atoms/Input'
import { parseCurrency } from 'utils/parse-currency-to-float'
import { BsInfoCircleFill } from 'react-icons/bs'
import { Tooltip } from 'components/Atoms/Tooltip'
import { ModalAddNewCategoryRevenues } from 'components/ModalAddNewCategory/Revenues'
import { MdAddCircle } from 'react-icons/md'

type FormInputs = {
  amount: string
  date: string
  transactionSubCategoryId: string
  recurrenceTimes?: string
}

const RevenuesNew: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<FormInputs>({ mode: 'onBlur' })
  const { revenues } = useSubCategories()
  const { push, goBack } = useHistory()
  const toast = useToast({ position: 'top' })

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [value, setValue] = React.useState('1')
  const [modalAddNewCategory, setModalAddNewCategory] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const onSubmit = handleSubmit((formData) => {
    value === '1' &&
      api
        .post('/transactions', {
          ...formData,
          amount: parseCurrency(formData.amount),
        })
        .then(() => {
          toast({
            status: 'success',
            title: 'Transação criada com sucesso!',
          })
          push('/revenues')
        })
        .catch((e) => console.log(e))

    value === '2' &&
      api
        .post('/transactions', {
          ...formData,
          amount: parseCurrency(formData.amount),
          recurrenceType: 'repeatAmount',
          recurrenceTimes: 12,
        })
        .then(() => {
          toast({
            status: 'success',
            title: 'Transação criada com sucesso!',
          })
          push('/revenues')
        })
        .catch((e) => console.log(e))

    value === '3' &&
      api
        .post('/transactions', {
          ...formData,
          amount: parseCurrency(formData.amount),
          recurrenceType: formData.recurrenceTimes && 'divideAmount',
          recurrenceTimes:
            formData.recurrenceTimes && parseInt(formData.recurrenceTimes),
        })
        .then(() => {
          toast({
            status: 'success',
            title: 'Transação criada com sucesso!',
          })
          push('/revenues')
        })
        .catch((e) => console.log(e))
  })

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <React.Fragment>
      <Header
        label="Receitas"
        value={0}
        isPositive={true}
        title="Registre um ganho"
      />
      <Main>
        <form onSubmit={onSubmit}>
          <VStack spacing={8}>
            <InputCurrency
              label="Valor"
              error={errors.amount}
              placeholder="Valor"
              isDirty={!!dirtyFields.amount}
              {...register('amount', { required: true })}
            />

            <Input
              label="Data"
              error={errors.date}
              placeholder="dd/mm/aaaa"
              isDirty={!!dirtyFields.date}
              type="date"
              {...register('date', { required: true })}
            />

            <FormControl>
              <HStack
                mb={'1rem'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <FormLabel
                  textAlign="center"
                  htmlFor={'transactionSubCategoryId'}
                  m={'0'}
                >
                  Categoria
                </FormLabel>
                <IconButton
                  icon={<MdAddCircle />}
                  onClick={() => setModalAddNewCategory(true)}
                  aria-label={''}
                  color={'#87CEFA'}
                  backgroundColor={'#FFFF'}
                  _hover={{
                    backgroundColor: '#f2f2f2',
                  }}
                />
                <ModalAddNewCategoryRevenues
                  isOpen={modalAddNewCategory}
                  onClose={() => setModalAddNewCategory(false)}
                  size={'sm'}
                  goBackModal={goBack}
                />
              </HStack>
              <Select
                id="category"
                {...register('transactionSubCategoryId', { required: true })}
              >
                {revenues.map(({ id, name, transactionSubCategories }) => (
                  <optgroup key={id} label={name}>
                    {transactionSubCategories.map((subCategory) => (
                      <option key={`${subCategory.id}`} value={subCategory.id}>
                        {subCategory.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <Flex flexDir={{ base: 'column', md: 'row' }}>
                <Flex width={{ base: '100%', md: '50%' }} alignItems={'center'}>
                  <FormLabel
                    htmlFor="repeat"
                    textAlign="center"
                    m="0"
                    maxW={'80px'}
                    pr={'1rem'}
                  >
                    Repetir?
                  </FormLabel>
                  <Tooltip
                    label={
                      'Caso seja recorrente, esse ganho irá se repetir nos próximos meses. Na hipótese de parcelado, selecione de 2 a 12 meses no campo "vezes", se deseja que o valor seja dividido nos próximos meses.'
                    }
                  >
                    <BsInfoCircleFill color="#ADD8E6" />
                  </Tooltip>
                  <RadioGroup onChange={setValue} value={value} w={'100%'}>
                    <Radio pl={'1rem'} value="1">
                      Único
                    </Radio>
                    <Radio pl={'1rem'} value="2">
                      Recorrente
                    </Radio>
                    <Radio pl={'1rem'} value="3">
                      Parcelado
                    </Radio>
                  </RadioGroup>
                </Flex>

                <Flex
                  width={{ base: '100%', md: '50%' }}
                  alignItems={'center'}
                  mt={{ base: '25px', md: '0' }}
                  ml={{ base: '0', md: '1rem' }}
                >
                  <FormLabel
                    htmlFor="recurrenceTimes"
                    textAlign="center"
                    m="0"
                    maxW={'80px'}
                    pr={'1rem'}
                  >
                    Vezes
                  </FormLabel>
                  <ChakraInput
                    label={''}
                    id="recurrenceTimes"
                    type="number"
                    min="2"
                    max="12"
                    step="1"
                    disabled={value ? value === '1' || value === '2' : true}
                    {...register('recurrenceTimes', { required: false })}
                    width={'100%'}
                  />
                </Flex>
              </Flex>
            </FormControl>
          </VStack>

          <VStack spacing={4} mt={8}>
            <Button
              type="submit"
              h="48px"
              borderRadius="40px"
              colorScheme="green"
              w="100%"
            >
              Registrar
            </Button>
            <Button
              type="button"
              h="48px"
              borderRadius="40px"
              variant="outline"
              colorScheme="red"
              w="100%"
              onClick={goBack}
              leftIcon={<FiArrowLeft />}
            >
              Voltar
            </Button>
          </VStack>
        </form>
      </Main>
      <NavbarBottom />
    </React.Fragment>
  )
}

export default RevenuesNew
