import React from 'react'
import { useHistory } from 'react-router-dom'

import { TypeSelectorContainer } from './styles'

type ReportPageSelectorProps = {
  type?: 'summary' | 'chart'
}

export const ReportPageSelector: React.FC<ReportPageSelectorProps> = ({
  type = 'chart',
}) => {
  const { push } = useHistory()

  return (
    <TypeSelectorContainer>
      <button
        style={{
          backgroundColor: '#D4526E',
          borderRadius: 8,
          padding: 8,
          marginLeft: 4,
          color: 'white',
          userSelect: 'none',
          border: 0,
          alignSelf: 'stretch',
          flex: 1,
        }}
        onClick={() =>
          push(`/charts/expenses${type === 'summary' ? '/summary' : ''}`)
        }
      >
        Gastos
      </button>
      <button
        style={{
          backgroundColor: '#22ff41',
          borderRadius: 8,
          padding: 8,
          marginLeft: 4,
          border: 0,
          userSelect: 'none',
          alignSelf: 'stretch',
          flex: 1,
        }}
        onClick={() =>
          push(`/charts/revenues${type === 'summary' ? '/summary' : ''}`)
        }
      >
        Ganhos
      </button>
      <button
        style={{
          backgroundColor: '#662E9B',
          borderRadius: 8,
          padding: 8,
          marginLeft: 4,
          color: 'white',
          border: 0,
          userSelect: 'none',
          alignSelf: 'stretch',
          flex: 1,
        }}
        onClick={() =>
          push(`/charts/investments${type === 'summary' ? '/summary' : ''}`)
        }
      >
        Investimentos
      </button>
    </TypeSelectorContainer>
  )
}
