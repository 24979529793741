import { Box } from '@chakra-ui/react'
import NavbarBottom from 'components/NavbarBottom'
import React from 'react'

const Private: React.FC = ({ children }) => {
  return (
    <Box w="100%" maxW="768px" m="0 auto" position="relative" minHeight="100vh">
      {children}
      <NavbarBottom />
    </Box>
  )
}

export default Private
