import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useAuth } from 'contexts/auth'

import { api } from 'services/api'

export const TRANSACTION_TYPES_SLUGS = [
  'investments',
  'revenues',
  'expenses',
] as const

export type TransactionTypeSlugStrings = typeof TRANSACTION_TYPES_SLUGS[number]

export type SubCategoriesValues = Array<{
  id: string
  name: string
  transactionSubCategories: Array<{
    id: string
    name: string
    icon_name: string
  }>
}>

type SubCategoriesContextValues = Record<
  TransactionTypeSlugStrings,
  SubCategoriesValues
>

export const SubCategoriesContext = createContext(
  {} as SubCategoriesContextValues
)

export const SubCategoriesProvider: React.FC = ({ children }) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { isLogged } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [subCategories, setSubCategories] =
    useState<SubCategoriesContextValues>({
      expenses: [],
      investments: [],
      revenues: [],
    })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const fetchSubCategoriesPerTransactionType = useCallback(async () => {
    const [i, r, e] = await Promise.all(
      TRANSACTION_TYPES_SLUGS.map((transactionType) =>
        api.get<SubCategoriesValues>(
          `/transaction_sub_categories?transactionTypeSlug=${transactionType}`
        )
      )
    )

    setSubCategories({
      investments: i.data,
      revenues: r.data,
      expenses: e.data,
    })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    if (!isLogged) return
    fetchSubCategoriesPerTransactionType()
  }, [fetchSubCategoriesPerTransactionType, isLogged])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <SubCategoriesContext.Provider value={subCategories}>
      {children}
    </SubCategoriesContext.Provider>
  )
}

export function useSubCategories() {
  const context = useContext(SubCategoriesContext)
  return context
}
