import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Flex,
  Button,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useAuth } from 'contexts/auth'
import React from 'react'
import { BsGraphUp, BsPerson, BsListUl } from 'react-icons/bs'
import { FaCalculator } from 'react-icons/fa'
import { GrMoney } from 'react-icons/gr'
import { IoMdClose, IoMdLogOut } from 'react-icons/io'
import { RiStockLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

type SidebarProps = {
  isOpen: boolean
  onClose: () => void
}

const Sidebar: React.FC<SidebarProps> = ({ onClose, isOpen }) => {
  const { signOut } = useAuth()

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="sm">
      <DrawerOverlay />
      <DrawerContent pb={'80px'}>
        <Flex
          alignItems={'center'}
          justifyContent={'space-between'}
          whiteSpace={'nowrap'}
        >
          <DrawerHeader>Menu Geral</DrawerHeader>
          <Text mr={'1.5rem'}>{/* {process.env.REACT_APP_VERSION} */}</Text>
        </Flex>
        <DrawerBody>
          <Flex>
            <Button onClick={onClose} rightIcon={<IoMdClose />}>
              Fechar
            </Button>

            <Button
              colorScheme="red"
              mx="4"
              onClick={signOut}
              rightIcon={<IoMdLogOut />}
            >
              Logout
            </Button>
          </Flex>

          <Heading my="4">Calculadoras</Heading>
          <Stack spacing="4">
            <Button as={Link} to="/tools/interest" rightIcon={<BsGraphUp />}>
              Juros Compostos
            </Button>
            <Button as={Link} to="/tools/independence" rightIcon={<GrMoney />}>
              Independência Financeira
            </Button>
            <Button as={Link} to="/tools/dreams" rightIcon={<GrMoney />}>
              Realização de sonhos
            </Button>
            <Button as={Link} to="/tools/stocks" rightIcon={<RiStockLine />}>
              Análise de ações
            </Button>
            <Button
              as={Link}
              to="/tools/LciLcaXCDB"
              rightIcon={<FaCalculator />}
            >
              Calculadora LCI/LCA x CDB
            </Button>
          </Stack>

          <Heading my="4">Conta</Heading>
          <Stack spacing="4">
            <Button disabled rightIcon={<BsPerson />}>
              Perfil
            </Button>
          </Stack>

          <Heading my="4">Configurações</Heading>
          <Stack spacing="4">
            <Button disabled rightIcon={<BsListUl />}>
              Categorias
            </Button>
            <Button as={Link} to="/reserve_target" rightIcon={<BsListUl />}>
              Meta de investimento
            </Button>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default Sidebar
