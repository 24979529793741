import * as yup from 'yup'

export const schema = yup
  .object()
  .shape({
    targetName: yup
      .string()
      .required('Insira um nome válido.')
      .typeError('Insira um nome válido.'),
    targetAmount: yup
      .string()
      .required('Insira um valor monetário.')
      .typeError('Insira um valor monetário.'),
    initialAmount: yup
      .string()
      .required('Insira um valor monetário.')
      .typeError('Insira um valor monetário.'),
    periodInMonths: yup
      .number()
      .required('Insira um período válido.')
      .typeError('Insira um período válido.')
      .min(1, 'Insira um período superior a 1 mês.')
      .max(1200, 'Insira um período inferior a 1200 meses.'),
    monthlyReturn: yup
      .string()
      .required('Insira uma taxa válida.')
      .typeError('Insira uma taxa válida.'),
  })
  .required()
