import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  BackgroundImage,
  Container,
  CTAContainer,
  FooterText,
  LogoContainer,
  Title,
} from './styles'

import backgroundImage from './background.png'
import logoImage from './logo.png'

const Intro: React.FC = () => {
  const history = useHistory()
  return (
    <Container>
      <BackgroundImage src={backgroundImage} alt="header image" />
      <LogoContainer>
        <Title>Bem vindo(a) ao</Title>
        <img src={logoImage} alt="" />
      </LogoContainer>
      <CTAContainer>
        <p>
          Ainda não é membro?{' '}
          <a
            href="https://financasdominadas.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            Clique aqui
          </a>
        </p>
        <button onClick={() => history.push('/login')}>Entrar</button>
      </CTAContainer>
      <FooterText>
        Ao continuar, você concorda com os <span>Termos de Serviço</span> e com
        a <span>Política de Privacidade</span> do Finanças Dominadas
      </FooterText>
    </Container>
  )
}

export default Intro
