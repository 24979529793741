import React from 'react'

import { Container } from './styles'

export type MainProps = {
  bgColor?: 'dark' | 'light'
}

const Main: React.FC<MainProps> = ({ children, bgColor = 'light' }) => {
  return (
    <Container bgColor={bgColor} id="scrollableMain">
      {children}
    </Container>
  )
}

export default Main
