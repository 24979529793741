import axios from 'axios'

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://127.0.0.1:3333',
})

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)
