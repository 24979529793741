import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

import { api } from 'services/api'

import Main from 'components/Main'
import Header from 'components/Header'
import NavbarBottom from 'components/NavbarBottom'
import TransactionWrapper from 'components/TransactionWrapper'
import Transaction, { TransactionProps } from 'components/Transaction'

import { PaginatedResponse } from 'types/api-meta-response'

const Home: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { push } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [page, setPage] = useState<number>(1)
  const [transactions, setTransactions] = useState<TransactionProps[]>([])
  const [sumOfTransactionValues, setSumOfTransactionValues] =
    useState<number>(0)
  const [hasMoreTransactions, setHasMoreTransactions] = useState<boolean>(true)
  const [isFetchingTransactions, setIsFetchingTransactions] =
    useState<boolean>(false)

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const fetchTransactions = useCallback(() => {
    if (isFetchingTransactions) return
    if (!hasMoreTransactions) return

    setIsFetchingTransactions(true)

    return api
      .get<PaginatedResponse<TransactionProps[]>>('/transactions', {
        params: { page, limit: 30 },
      })
      .then((response) => {
        if (!response.data.data.length) {
          setHasMoreTransactions(false)
          return
        }

        setTransactions((currentTransaction) => [
          ...currentTransaction,
          ...response.data.data,
        ])
        setPage((page) => page + 1)
      })
      .catch((error) => {
        console.trace(error)
        setHasMoreTransactions(false)
      })
      .finally(() => {
        setIsFetchingTransactions(false)
      })
  }, [hasMoreTransactions, isFetchingTransactions, page])

  async function refreshTransactions() {
    setTransactions([])
    setPage(1)
    setHasMoreTransactions(true)
  }

  const onTransactionClick = useCallback(
    (transaction: TransactionProps) => {
      const {
        transactionType: { slug },
        id,
      } = transaction
      push(`/${slug}/${id}`)
    },
    [push]
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    if (page > 1) return // this is needed because i just want the effect to run once
    fetchTransactions()
  }, [fetchTransactions, page])

  useEffect(() => {
    api
      .get<number>('/statistics/by_type')
      .then(({ data: total }) => setSumOfTransactionValues(total))
      .catch((err) => {
        console.trace('Failed to fetch sum. ', err)
      })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <React.Fragment>
      <Header
        label="Balanço"
        value={sumOfTransactionValues}
        isPositive={sumOfTransactionValues > 0}
      />
      <Main>
        <InfiniteScroll
          style={{ height: '100%' }}
          dataLength={transactions.length}
          next={fetchTransactions}
          hasMore={hasMoreTransactions}
          scrollableTarget="scrollableMain"
          scrollThreshold={0.9}
          loader={<div>Buscando lançamentos...</div>}
          refreshFunction={refreshTransactions}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <h4 style={{ textAlign: 'center' }}>
              &#8595; Arraste para baixo para atualizar
            </h4>
          }
          releaseToRefreshContent={
            <h4 style={{ textAlign: 'center' }}>
              &#8593; Solte para atualizar
            </h4>
          }
          endMessage={
            <p style={{ textAlign: 'center', marginTop: 24 }}>
              <b>Você viu todas as suas transações.</b>
            </p>
          }
        >
          {transactions.map((transaction) => (
            <TransactionWrapper
              key={transaction.id}
              onClick={() => onTransactionClick(transaction)}
            >
              <Transaction {...transaction} />
            </TransactionWrapper>
          ))}
        </InfiniteScroll>
      </Main>
      <NavbarBottom />
    </React.Fragment>
  )
}

export default Home
