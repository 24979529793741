import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'
import {
  Button,
  useToast,
  FormControl,
  FormLabel,
  Select,
  VStack,
  Divider,
  IconButton,
  HStack,
} from '@chakra-ui/react'

import { useSubCategories } from 'contexts/sub-categories'

import { api } from 'services/api'

import { TransactionProps } from 'components/Transaction'
import NavbarBottom from 'components/NavbarBottom'
import Header from 'components/Header'
import Main from 'components/Main'
import InputCurrency from 'components/Atoms/InputCurrency'
import Input from 'components/Atoms/Input'
import { parseCurrency } from 'utils/parse-currency-to-float'
import { ModalDelete } from 'components/Modal/Delete'
import AlertDialogComponent from 'components/AlertDialog'
import { ModalEdit } from 'components/Modal/Edit'
import AlertDialogComponentEdit from 'components/AlertDialogEdit'
import { ModalAddNewCategoryExpenses } from 'components/ModalAddNewCategory/Expenses'
import { MdAddCircle } from 'react-icons/md'

type FormInputs = {
  amount: string
  date: string
  transactionSubCategoryId: string
}

const ExpensesShow: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Refs.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const cancelRef = useRef(null)

  /*
  |-----------------------------------------------------------------------------
  | Types.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { push, goBack } = useHistory()
  const { expenses } = useSubCategories()
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<FormInputs>({ mode: 'onBlur' })
  const { id: transactionId } = useParams<{ id: string }>()
  const toast = useToast({ position: 'top' })

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [transaction, setTransaction] = useState<TransactionProps>()
  const [isConfirmDestroyModalOpen, setIsConfirmDestroyModalOpen] =
    useState(false)
  const [
    isConfirmDestroyModalOpenDeleteAllRecords,
    setIsConfirmDestroyModalOpenDeleteAllRecords,
  ] = useState(false)
  const [
    isConfirmDestroyModalOpenDeleteNextRecords,
    setIsConfirmDestroyModalOpenDeleteNextRecords,
  ] = useState(false)
  const [isConfirmEditOpen, setIsConfirmEditOpen] = useState(false)
  const [isConfirmEditAllRecords, setIsConfirmEditAllRecords] = useState(false)
  const [isConfirmEditNextRecords, setIsConfirmEditNextRecords] =
    useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false)
  const [modalAddNewCategory, setModalAddNewCategory] = useState(false)
  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const update = useCallback(async () => {
    api
      .put(`/transactions/${transactionId}`, {
        amount: parseCurrency(getValues('amount')),
        date: getValues('date'),
        transactionSubCategoryId: getValues('transactionSubCategoryId'),
      })
      .then(() => {
        toast({
          status: 'success',
          title: 'Transação atualizada com sucesso!',
        })
        push('/expenses')
      })
      .catch((e) => console.log(e))
  }, [push, transactionId, toast, getValues])

  const updateAllRecords = useCallback(async () => {
    api
      .put(`/transactions/${transactionId}`, {
        amount: parseCurrency(getValues('amount')),
        date: getValues('date'),
        transactionSubCategoryId: getValues('transactionSubCategoryId'),
        applyNext: true,
        applyPrevious: true,
      })
      .then(() => {
        toast({
          status: 'success',
          title: 'Transação atualizada com sucesso!',
        })
        push('/expenses')
      })
      .catch((e) => console.log(e))
  }, [push, transactionId, toast, getValues])

  const updateNextRecords = useCallback(async () => {
    await api
      .put(`/transactions/${transactionId}`, {
        amount: parseCurrency(getValues('amount')),
        date: getValues('date'),
        transactionSubCategoryId: getValues('transactionSubCategoryId'),
        applyNext: true,
      })

      .then(() => {
        toast({
          status: 'success',
          title: 'Transação atualizada com sucesso!',
        })
        push('/expenses')
      })
      .catch((e) => console.log(e))
  }, [push, transactionId, toast, getValues])

  const destroy = useCallback(() => {
    if (!transactionId) return

    api
      .delete(`/transactions/${transactionId}`)
      .then(() => {
        toast({
          status: 'success',
          title: 'Transação removida com sucesso!',
        })
        goBack()
      })
      .catch((err) => {
        console.trace(err)
      })
  }, [goBack, toast, transactionId])

  const editConfirm = useCallback(() => {
    if (!transaction) return
    setModalEditIsOpen(false)
    setIsConfirmEditOpen(true)
  }, [transaction])

  const editAllRecords = useCallback(() => {
    if (!transaction) return
    setModalEditIsOpen(false)
    setIsConfirmEditAllRecords(true)
  }, [transaction])

  const editNextRecords = useCallback(() => {
    if (!transaction) return
    setModalEditIsOpen(false)
    setIsConfirmEditNextRecords(true)
  }, [transaction])

  const destroyConfirm = useCallback(() => {
    if (!transaction) return

    setIsConfirmDestroyModalOpen(true)
  }, [transaction])

  const deleteOneRecord = useCallback(() => {
    if (!transaction) return
    setModalIsOpen(false)
    setIsConfirmDestroyModalOpen(true)
  }, [transaction])

  const deleteAllRecords = useCallback(() => {
    if (!transaction) return
    setModalIsOpen(false)
    setIsConfirmDestroyModalOpenDeleteAllRecords(true)
  }, [transaction])

  const destroyConfirmAllRecords = useCallback(() => {
    if (!transactionId) return
    api
      .delete(`/transactions/${transactionId}`, {
        params: {
          applyNext: true,
          applyPrevious: true,
        },
      })
      .then(() => {
        toast({
          status: 'success',
          title: 'Transação removida com sucesso!',
        })
        goBack()
      })
      .catch((err) => {
        console.trace(err)
      })
  }, [goBack, toast, transactionId])

  const destroyConfirmNextRecords = useCallback(() => {
    if (!transactionId) return
    api
      .delete(`/transactions/${transactionId}`, {
        params: {
          applyNext: true,
        },
      })

      .then(() => {
        toast({
          status: 'success',
          title: 'Transação removida com sucesso!',
        })
        goBack()
      })
      .catch((err) => {
        console.trace(err)
      })
  }, [goBack, toast, transactionId])

  const deleteNextRecords = useCallback(() => {
    if (!transaction) return
    setModalIsOpen(false)
    setIsConfirmDestroyModalOpenDeleteNextRecords(true)
  }, [transaction])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!transactionId) return
    const loadData = async () => {
      const data = await api
        .get<TransactionProps>(`/transactions/${transactionId}`)
        .then(({ data }) => setTransaction(data))
        .catch((err) => console.trace(err))
      return data
    }
    loadData()
  }, [transactionId])

  useEffect(() => {
    if (!transaction) return
    reset({
      amount: (transaction.amount < 0
        ? transaction.amount * -1
        : transaction.amount
      ).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      date: transaction.date,
      transactionSubCategoryId: transaction.transactionSubCategory.id,
    })
  }, [setValue, transaction, getValues, reset, watch])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <React.Fragment>
      <Header
        label="Gastos"
        value={0}
        isPositive={true}
        title="Detalhe de um gasto"
      />
      <Main>
        <form onSubmit={handleSubmit(update)}>
          <VStack spacing={8}>
            <InputCurrency
              label="Valor"
              error={errors.amount}
              placeholder="Valor"
              isDirty={!!dirtyFields.amount}
              {...register('amount', { required: true })}
            />
            <Input
              label="Data"
              error={errors.date}
              placeholder="dd/mm/aaaa"
              isDirty={!!dirtyFields.date}
              type="date"
              {...register('date', { required: true })}
            />
            <FormControl>
              <HStack
                mb={'1rem'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <FormLabel
                  textAlign="center"
                  htmlFor={'transactionSubCategoryId'}
                  m={'0'}
                >
                  Categoria
                </FormLabel>
                <IconButton
                  icon={<MdAddCircle />}
                  onClick={() => setModalAddNewCategory(true)}
                  aria-label={''}
                  color={'#87CEFA'}
                  backgroundColor={'#FFFF'}
                  _hover={{
                    backgroundColor: '#f2f2f2',
                  }}
                />
                <ModalAddNewCategoryExpenses
                  isOpen={modalAddNewCategory}
                  onClose={() => setModalAddNewCategory(false)}
                  size={'sm'}
                  goBackModal={goBack}
                />
              </HStack>
              <Select
                id="category"
                {...register('transactionSubCategoryId', { required: true })}
              >
                {expenses.map(({ id, name, transactionSubCategories }) => (
                  <optgroup key={id} label={name}>
                    {transactionSubCategories.map((subCategory) => (
                      <option key={`${subCategory.id}`} value={subCategory.id}>
                        {subCategory.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Select>
            </FormControl>
            {transaction && transaction?.recurrence_times > 1 ? (
              <>
                <Button
                  onClick={() => setModalEditIsOpen(true)}
                  w="100%"
                  colorScheme="green"
                  borderRadius="40px"
                  h="48px"
                >
                  Atualizar dados
                </Button>
                <ModalEdit
                  isOpen={modalEditIsOpen}
                  size="sm"
                  onClose={() => setModalEditIsOpen(false)}
                  EditAllRecords={() => editAllRecords()}
                  EditOneRecord={() => editConfirm()}
                  EditOnlyPreviosRecords={() => editNextRecords()}
                  goBack={goBack}
                ></ModalEdit>
              </>
            ) : (
              <Button
                type="submit"
                w="100%"
                colorScheme="green"
                borderRadius="40px"
                h="48px"
                /* onClick={() =>
                  console.log(getValues('amount').replace('R$', ''))
                } */
              >
                Atualizar dados
              </Button>
            )}
          </VStack>
        </form>

        <Divider mt={10} />

        <VStack spacing={4} mt={8}>
          {transaction && transaction?.recurrence_times > 1 ? (
            <>
              <Button
                onClick={() => setModalIsOpen(true)}
                colorScheme="red"
                w="100%"
                mt="4"
                h="48px"
                borderRadius="40px"
                variant="outline"
              >
                Excluir registro
              </Button>
              <Button
                onClick={goBack}
                variant="ghost"
                leftIcon={<FiArrowLeft />}
              >
                Voltar
              </Button>
              <ModalDelete
                isOpen={modalIsOpen}
                size="sm"
                onClose={() => setModalIsOpen(false)}
                DeleteAllRecords={() => deleteAllRecords()}
                DeleteOneRecord={() => deleteOneRecord()}
                DeleteOnlyPreviosRecords={() => deleteNextRecords()}
                goBack={goBack}
              ></ModalDelete>
            </>
          ) : (
            <>
              <Button
                onClick={destroyConfirm}
                colorScheme="red"
                w="100%"
                mt="4"
                h="48px"
                borderRadius="40px"
                variant="outline"
              >
                Excluir registro
              </Button>
              <Button
                onClick={goBack}
                variant="ghost"
                leftIcon={<FiArrowLeft />}
              >
                Voltar
              </Button>
            </>
          )}
        </VStack>
      </Main>

      <NavbarBottom />

      <AlertDialogComponent
        isOpen={isConfirmDestroyModalOpen}
        onClose={() => setIsConfirmDestroyModalOpen(false)}
        leastDestructiveRef={cancelRef}
        onClickOk={destroy}
        onClickCancel={() => setIsConfirmDestroyModalOpen(false)}
        refButton={cancelRef}
        alertText="Tem certeza que deseja remover apenas esse registro? Essa é
        uma ação irreversível."
      />

      <AlertDialogComponent
        isOpen={isConfirmDestroyModalOpenDeleteAllRecords}
        onClose={() => setIsConfirmDestroyModalOpenDeleteAllRecords(false)}
        leastDestructiveRef={cancelRef}
        onClickOk={destroyConfirmAllRecords}
        onClickCancel={() =>
          setIsConfirmDestroyModalOpenDeleteAllRecords(false)
        }
        refButton={cancelRef}
        alertText="Tem certeza que deseja remover todos os registros? Essa é
        uma ação irreversível."
      />

      <AlertDialogComponent
        isOpen={isConfirmDestroyModalOpenDeleteNextRecords}
        onClose={() => setIsConfirmDestroyModalOpenDeleteNextRecords(false)}
        leastDestructiveRef={cancelRef}
        onClickOk={destroyConfirmNextRecords}
        onClickCancel={() =>
          setIsConfirmDestroyModalOpenDeleteNextRecords(false)
        }
        refButton={cancelRef}
        alertText="Tem certeza que deseja remover esse e os próximos registros? Essa é
        uma ação irreversível."
      />

      {/* Edits alert dialog */}

      <AlertDialogComponentEdit
        isOpen={isConfirmEditOpen}
        onClose={() => setIsConfirmEditOpen(false)}
        leastDestructiveRef={cancelRef}
        onClickOk={update}
        onClickCancel={() => setIsConfirmEditOpen(false)}
        refButton={cancelRef}
        alertText="Tem certeza que deseja remover esse registro? Essa é
        uma ação irreversível."
      />

      <AlertDialogComponentEdit
        isOpen={isConfirmEditAllRecords}
        onClose={() => setIsConfirmEditAllRecords(false)}
        leastDestructiveRef={cancelRef}
        onClickOk={updateAllRecords}
        onClickCancel={() => setIsConfirmEditAllRecords(false)}
        refButton={cancelRef}
        alertText="Tem certeza que deseja remover todos os próximos registros? Essa é
        uma ação irreversível."
      />

      <AlertDialogComponentEdit
        isOpen={isConfirmEditNextRecords}
        onClose={() => setIsConfirmEditNextRecords(false)}
        leastDestructiveRef={cancelRef}
        onClickOk={updateNextRecords}
        onClickCancel={() => setIsConfirmEditNextRecords(false)}
        refButton={cancelRef}
        alertText="Tem certeza que deseja remover esse e os próximos registros? Essa é
        uma ação irreversível."
      />
    </React.Fragment>
  )
}

export default ExpensesShow
