import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BarChart2 } from 'react-feather'
import { groupBy, Dictionary } from 'lodash'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'

import { api } from 'services/api'

import { format } from 'utils/currency'

import Main from 'components/Main'
import Header from 'components/Header'
import NavbarBottom from 'components/NavbarBottom'
import { ReportPageSelector } from 'components/ReportPageSelector'

import { TransactionTypesEnum, TransactionTypeSlugs } from '../types'

import {
  Line,
  Percent,
  SummaryLine,
  SummaryLineHeader,
  Value,
  ValueBox,
} from './styles'

type SummaryAmount = {
  category: string
  sum_amount: string
  sub_category: string
}

const Summary: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { register } = useForm()

  const { transactionTypeSlug } = useParams<{
    transactionTypeSlug: TransactionTypeSlugs
  }>()

  const transactionTypeName = TransactionTypesEnum[transactionTypeSlug]

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [summaryLines, setSummaryLines] = useState<Dictionary<SummaryAmount[]>>(
    {}
  )
  const [summaryCategorySums, setSummaryCategorySums] = useState<
    Record<string, number>
  >({})

  const [summarySum, setSummarySum] = useState<number>(1)

  const [period, setPeriod] = useState('year')

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    api
      .get<SummaryAmount[]>('/statistics/summary_by_type', {
        params: {
          period,
          transactionTypeSlug,
        },
      })
      .then(({ data }) => {
        if (data.length === 0) {
          Swal.fire('Aviso', 'Nenhum dado para o período selecionado', 'info')
          setSummaryLines({})
          return
        }

        const groupedData = groupBy(data, 'category')
        setSummaryLines(groupedData)

        const sumByCategory = data.reduce((acc, cur) => {
          acc[cur.category] = acc[cur.category]
            ? acc[cur.category] + Number(cur.sum_amount)
            : Number(cur.sum_amount)
          return acc
        }, {} as Record<string, number>)

        setSummaryCategorySums(sumByCategory)

        const totalSum = data.reduce((acc, cur) => {
          return acc + Number(cur.sum_amount)
        }, 0)

        setSummarySum(totalSum)
      })
      .catch((err) => {
        console.trace(err)
      })
  }, [transactionTypeSlug, period])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <React.Fragment>
      <Header
        label="Balanço"
        value={0}
        isPositive
        title={`Acompanhe\nseus ${transactionTypeName}`}
        pagePath={`/charts/${transactionTypeSlug}`}
        pagePathIcon={BarChart2}
      />
      <Main>
        <ReportPageSelector type="summary" />

        <form>
          <label htmlFor="period" style={{ textAlign: 'center' }}>
            Selecione o período
          </label>
          <select
            id="period"
            {...register('period', {
              required: true,
              onChange: (c) => setPeriod(c.target.value),
            })}
          >
            <option value="year">ano</option>
            <option value="quarter">trimestre</option>
            <option value="month">mês</option>
          </select>
        </form>

        {Object.entries(summaryLines).map(([category, data]) => (
          <SummaryLine key={category}>
            <SummaryLineHeader>
              <h3>{category}</h3>
              <ValueBox>
                <Value isHeader>{format(summaryCategorySums[category])}</Value>
                <Percent>
                  {(
                    (Number(summaryCategorySums[category]) / summarySum) *
                    100
                  ).toFixed(2)}{' '}
                  %
                </Percent>
              </ValueBox>
            </SummaryLineHeader>
            {data.map(({ sub_category, sum_amount }) => (
              <div
                style={{ display: 'flex', justifyContent: 'space-between' }}
                key={sub_category}
              >
                <Line>{sub_category}</Line>
                <ValueBox>
                  <Value>{format(Number(sum_amount))}</Value>
                  <Percent>
                    {((Number(sum_amount) / summarySum) * 100).toFixed(2)} %
                  </Percent>
                </ValueBox>
              </div>
            ))}
          </SummaryLine>
        ))}
      </Main>
      <NavbarBottom />
    </React.Fragment>
  )
}

export default Summary
