import {
  Box,
  Button,
  Divider,
  Switch,
  // Heading,
  VStack,
  Text,
  Heading,
  Badge,
  useToast,
} from '@chakra-ui/react'
// import { v4 as uuid } from 'uuid'
// import { useForm } from 'react-hook-form'
import Input from 'components/Atoms/Input'
import Header from 'components/Header'
import Private from 'layouts/Private'
import React, { useCallback, useMemo, useState } from 'react'
// import { schema } from './schema'
// import { yupResolver } from '@hookform/resolvers/yup'
// import InputCurrency from 'components/Atoms/InputCurrency'
// import InputInteger from 'components/Atoms/InputInteger'
// import InputDecimal from 'components/Atoms/InputDecimal'
// import { parseCurrency } from 'utils/parse-currency-to-float'
// import { parseDecimal } from 'utils/parse-decimal'
// import { format } from 'utils/currency'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Link, useHistory } from 'react-router-dom'
import { v4 } from 'uuid'

type FormInputs = {
  stockName: string
}

export type Objective = FormInputs & { points: number; uuid: string }

const QUESTIONS = [
  {
    title: 'Governança',
    question:
      'A empresa possui uma boa gestão? Avalie o histórico (Pesquise o nome da empresa no Google e no YouTube). Se possuir escândalo marque NÃO.',
    uuid: '3328d4da-69d0-4563-9d81-7f34049baac9',
    answer: false,
  },
  {
    title: 'Tempo',
    question: 'A empresa fez IPO há mais de 5 anos?',
    uuid: 'bae7a98a-2f1f-4366-b8af-5d5a16120b53',
    answer: false,
  },
  {
    title: 'Lucro',
    question:
      'A empresa obteve lucro recorrente e progressivo nos últimos 5 anos?',
    uuid: '972c2325-7469-4287-babe-f63de4d36635',
    answer: false,
  },
  {
    title: 'ROE',
    question: 'A empresa apresentou ROE > 8%? Considere os anos anteriores.',
    uuid: '65d3ce09-95cc-4e10-837c-ca49ee9ae846',
    answer: false,
  },
  {
    title: 'Dividendos',
    question: 'A empresa possui histórico de pagamento de dividendos?',
    uuid: '5e4c2d4d-415b-4180-ac96-d7016ff7eb7b',
    answer: false,
  },
  {
    title: 'P/VP',
    question: 'A empresa está negociada P/VP<5?',
    uuid: '4ff10195-b528-4752-ac30-a4c55d8b3e15',
    answer: false,
  },
  {
    title: 'Dívida líquida e patrimônio líquido',
    question: 'A dívida líquida é menor que o patrimônio líquido da empresa.',
    uuid: '42a0fcf4-9a8b-4c1f-8c3f-09f5ca50acd8',
    answer: false,
  },
  {
    title: 'Dívida Líquida e EBITDA',
    question: 'A dívida líquida / EBITDA é < 3',
    uuid: '67c65c6c-0d9f-4d25-b8f1-6c66658d6d3e',
    answer: false,
  },
  {
    title: 'Perenidade',
    question:
      'A empresa existe há mais de 50 anos? Considerar a data de fundação e não o IPO',
    uuid: 'bd907426-21ba-44ac-9b17-d29b154c7652',
    answer: false,
  },
  {
    title: 'P/L',
    question: 'O Preço/Lucro está abaixo de 30',
    uuid: '3aed38a0-627d-48bf-9abe-f8369efb1df7',
    answer: false,
  },
  {
    title: 'CAGR',
    question:
      'A empresa tem um crescimento de receitas (ou lucro) acima de 5% nos últimos 5 anos',
    uuid: '79049e81-0144-40b9-878a-4b85ba65abcc',
    answer: false,
  },
  {
    title: 'Vantagens Competitivas',
    question:
      'É líder nacional ou mundial no setor em que atua? Só considerar líder se for primeira colocada',
    uuid: 'ffd95efa-5c06-439f-a721-489ea613b687',
    answer: false,
  },
  {
    title: 'Viés',
    question:
      'A empresa está livre de grandes oscilações no mercado? (Se o preço da cotação da empresa subiu ou desceu mais de 30% nos últimos 6 meses, não marcar)',
    uuid: '29747b13-bb04-4073-8edf-ab2366ea42a4',
    answer: false,
  },
  {
    title: 'Independência',
    question:
      'A empresa é livre de controle ESTATAL ou não está limitada a um único cliente',
    uuid: '4d7d0e58-bc5a-4f0e-8004-2a383f4af1e4',
    answer: false,
  },
  {
    title: 'Tamanho',
    question: 'A empresa possui patrimônio líquido acima de 5 bilhões',
    uuid: '7aa1307e-0a94-4574-ace1-a6600a22f104',
    answer: false,
  },
  {
    title: 'Liquidez média diária',
    question:
      'A empresa possui liquidez média diária maior que 150 milhões de reais',
    uuid: '5ddd508e-bd8a-4c47-a5eb-5718b85d0d98',
    answer: false,
  },
]

const Stocks: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const toast = useToast({ position: 'top' })

  const { push } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [stockName, setStockName] = useState<string>()
  const [questions, setQuestions] = useState(QUESTIONS)

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const points = useMemo(() => {
    return questions.reduce((acc, question) => {
      return acc + (question.answer ? 1 : -1)
    }, 0)
  }, [questions])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = useCallback(() => {
    if (!stockName) return

    const localStorageItems = localStorage.getItem('@fd:stocks')

    const dreams = localStorageItems
      ? (JSON.parse(localStorageItems) as Objective[])
      : []

    dreams.push({
      stockName,
      points,
      uuid: v4(),
    })

    localStorage.setItem('@fd:stocks', JSON.stringify(dreams))

    toast({
      title: 'Parabéns!',
      description: `Você registrou a ação ${stockName} pontos`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    })

    push('/tools/stocks')
  }, [points, push, stockName, toast])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Private>
      <Header title={stockName ? `${stockName}` : 'Cadastrar uma nova ação'} />
      <Box h="72vh" p="2rem" pb="106px" overflowY="auto">
        <Box>
          <VStack spacing={4}>
            <Input
              label="Nome da ação"
              placeholder="Exemplo: PETR4"
              name="stockName"
              onChange={(e) => setStockName(e.target.value)}
            />

            {questions.map((question) => (
              <React.Fragment key={question.uuid}>
                <Divider />
                <Heading textAlign="left" size="md">
                  {question.title}
                </Heading>
                <Text>{question.question}</Text>
                <Switch
                  colorScheme="green"
                  checked={question.answer}
                  onChange={(e) => {
                    setQuestions(
                      questions.map((q) =>
                        q.uuid === question.uuid
                          ? { ...q, answer: e.target.checked }
                          : q
                      )
                    )
                  }}
                />
              </React.Fragment>
            ))}
          </VStack>

          <Divider mt={8} />

          <VStack spacing={4} mt={8}>
            <Text textAlign="center">
              <Badge
                colorScheme={
                  points <= -8 ? 'red' : points <= 8 ? 'yellow' : 'green'
                }
              >
                RESULTADO
              </Badge>
              <br />
              {points <= -8
                ? 'Pouco viável para estudo e aquisição'
                : points <= 8
                ? 'Moderadamente viável para estudo e aquisição'
                : 'Fortemente viável para estudo e aquisição'}
            </Text>

            <Button
              colorScheme="green"
              type="submit"
              w="100%"
              mt={8}
              disabled={!stockName}
              onClick={onSubmit}
            >
              Salvar
            </Button>

            <Button
              variant="ghost"
              w="100%"
              as={Link}
              to="/private/tools/dreams"
              leftIcon={<ArrowBackIcon />}
            >
              Voltar
            </Button>
          </VStack>
        </Box>
      </Box>
    </Private>
  )
}

export default Stocks
