import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Text,
  Button,
  Heading,
  HStack,
  Box,
  VStack,
  Flex,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import InputCurrency from 'components/Atoms/InputCurrency'
import React, { useState } from 'react'
import { format } from 'utils/currency'
import { parseCurrency } from 'utils/parse-currency-to-float'
import { parseDecimal } from 'utils/parse-decimal'
import InputInteger from 'components/Atoms/InputInteger'

type DiscoveryProps = {
  previous(): void
  next(): void
  targetAmount?: number
  setGlobalMonthsToAccomplish(months: number): void
}

type FormInputs = {
  initialCapital: string
  monthlyInvestments: string
  monthlyReturn: string
}

export const Acumulation: React.FC<DiscoveryProps> = ({
  next,
  previous,
  targetAmount,
  setGlobalMonthsToAccomplish,
}) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    register,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm<FormInputs>()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [monthsToAccomplish, setMonthsToAccomplish] = useState<number>()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const calculateMonthsToAccomplish = (
    floatMonthInvestments: number,
    floatInitialCapital: number,
    floatMonthReturn: number
  ) => {
    if (!targetAmount) {
      console.trace('Missing target amount')
      return
    }

    const MAX_ITERATIONS = Infinity
    let currentCapital = floatInitialCapital

    for (let i = 1; i < MAX_ITERATIONS; i++) {
      currentCapital =
        currentCapital * Math.pow(floatMonthReturn + 1, 1 / 12) +
        floatMonthInvestments

      if (currentCapital >= targetAmount) {
        console.log(`${i} iterations needed.`)
        setMonthsToAccomplish(i)
        setGlobalMonthsToAccomplish(i)
        break
      }
    }
  }

  const submitHandler = handleSubmit((data) => {
    const floatMonthInvestments = parseCurrency(data.monthlyInvestments)
    const floatInitialCapital = parseCurrency(data.initialCapital)
    const floatMonthReturn = parseDecimal(data.monthlyReturn) / 100

    calculateMonthsToAccomplish(
      floatMonthInvestments,
      floatInitialCapital,
      floatMonthReturn
    )
  })

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <>
      {!monthsToAccomplish && (
        <>
          <Heading>Acumulação</Heading>
          <Text mt={2} opacity={0.4} fontSize={12}>
            Esta aba irá calcular quanto tempo irá levar até que você atinja seu
            objetivo patrimonial.
          </Text>

          <Box as="form" onSubmit={submitHandler} my={4}>
            <VStack spacing={4}>
              <InputCurrency
                label="Patrimônio incial"
                placeholder="Exemplo: R$ 200.000,00"
                error={errors.initialCapital}
                isDirty={!!dirtyFields.initialCapital}
                {...register('initialCapital', {
                  required: 'Campo obrigatório',
                })}
              />

              <InputCurrency
                label="Aportes mensais"
                placeholder="Exemplo: R$ 20.000,00"
                error={errors.monthlyInvestments}
                isDirty={!!dirtyFields.monthlyInvestments}
                {...register('monthlyInvestments', {
                  required: 'Campo obrigatório',
                })}
              />

              <InputInteger
                label="Taxa Anual de Retorno Acima da Inflação"
                placeholder="Exemplo: 5,2"
                error={errors.monthlyReturn}
                isDirty={!!dirtyFields.monthlyReturn}
                {...register('monthlyReturn')}
              />
            </VStack>

            <Button type="submit" colorScheme="green" mt={4}>
              Calcular
            </Button>
          </Box>
        </>
      )}

      {!!monthsToAccomplish && !!targetAmount && (
        <Flex flexDirection="column" height="100%">
          <Heading as="h3">Resultado</Heading>
          <Text mt={2} opacity={0.8} fontSize={24}>
            Você levará <strong>{monthsToAccomplish} meses</strong>, o
            equivalente a{' '}
            <strong>{(monthsToAccomplish / 12).toFixed(1)} anos </strong> para
            atingir o objetivo de <strong>{format(targetAmount)}</strong>.
          </Text>

          <HStack mt="auto">
            <Button
              colorScheme="gray"
              width="100%"
              leftIcon={<ArrowBackIcon />}
              onClick={previous}
            >
              Voltar
            </Button>

            <Button
              colorScheme="green"
              width="100%"
              rightIcon={<ArrowForwardIcon />}
              onClick={next}
            >
              Avançar
            </Button>
          </HStack>
        </Flex>
      )}
    </>
  )
}
