import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory } from 'react-router-dom'

import { api } from 'services/api'

import Transaction, { TransactionProps } from 'components/Transaction'
import TransactionWrapper from 'components/TransactionWrapper'
import NavbarBottom from 'components/NavbarBottom'
import Main from 'components/Main'

import { PaginatedResponse } from 'types/api-meta-response'
import { Select } from '@chakra-ui/react'
import HeaderFilter from 'components/HeaderFilter'

type transactionByMonth = Array<{
  date: string
  transactions: Array<TransactionProps>
  sumOfTransactionValues?: number
}>

const Revenues: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { push } = useHistory()
  const [page, setPage] = useState<number>(1)
  const [pageMonth, setPageMonth] = useState<number>(1)
  const [transactions, setTransactions] = useState<TransactionProps[]>([])
  const [transactionsByMonth, setTransactionsByMonth] =
    useState<transactionByMonth>([])
  const [sumOfTransactionValues, setSumOfTransactionValues] =
    useState<number>(0)
  const [hasMoreTransactions, setHasMoreTransactions] = useState<boolean>(true)
  const [hasMoreDateTransactions, setHasMoreDateTransactions] =
    useState<boolean>(true)
  const [isFetchingTransactions, setIsFetchingTransactions] =
    useState<boolean>(false)
  const [isFetchingDateTransactions, setIsFetchingDateTransactions] =
    useState<boolean>(false)
  const [month, setMonth] = useState<string>('')

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const options = useMemo(() => {
    return [
      {
        value: 'January',
        label: 'Janeiro',
      },
      {
        value: 'February',
        label: 'Fevereiro',
      },
      {
        value: 'March',
        label: 'Março',
      },
      {
        value: 'April',
        label: 'Abril',
      },
      {
        value: 'May',
        label: 'Maio',
      },
      {
        value: 'June',
        label: 'Junho',
      },
      {
        value: 'July',
        label: 'Julho',
      },
      {
        value: 'August',
        label: 'Agosto',
      },
      {
        value: 'September',
        label: 'Setembro',
      },
      {
        value: 'October',
        label: 'Outubro',
      },
      {
        value: 'November',
        label: 'Novembro',
      },
      {
        value: 'December',
        label: 'Dezembro',
      },
    ]
  }, [])
  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const fetchTransactions = useCallback(() => {
    if (isFetchingTransactions) return
    if (!hasMoreTransactions) return

    setIsFetchingTransactions(true)

    return api
      .get<PaginatedResponse<TransactionProps[]>>('/transactions', {
        params: { page, limit: 30, transactionTypeSlug: 'revenues' },
      })
      .then((response) => {
        if (!response.data.data.length) {
          setHasMoreTransactions(false)
          return
        }

        setTransactions((currentTransaction) => [
          ...currentTransaction,
          ...response.data.data,
        ])
        setPage((page) => page + 1)
      })
      .catch((error) => {
        console.trace(error)
        setHasMoreTransactions(false)
      })
      .finally(() => {
        setIsFetchingTransactions(false)
      })
  }, [hasMoreTransactions, isFetchingTransactions, page])

  async function refreshTransactions() {
    setTransactions([])
    setPage(1)
    setHasMoreTransactions(true)
  }

  async function refreshDateTransactions() {
    setTransactionsByMonth([])
    setPageMonth(1)
    setHasMoreDateTransactions(true)
  }

  const onTransactionClick = useCallback(
    (transaction: TransactionProps) => {
      push(`/revenues/${transaction.id}`)
    },
    [push]
  )

  const fetchTransactionsByMonth = useCallback(() => {
    if (isFetchingDateTransactions) return
    if (!setHasMoreDateTransactions) return

    setIsFetchingDateTransactions(true)
    return api
      .get<PaginatedResponse<TransactionProps[]>>('/transactions', {
        params: { pageMonth, limit: 300, transactionTypeSlug: 'revenues' },
      })
      .then((response) => {
        if (!response.data.data.length) {
          setHasMoreTransactions(false)
          return
        }
        const datesJanuary = response.data.data
          .filter((transaction) => transaction.date.slice(5, 7) === '01')
          .sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })
        const datesFebruary = response.data.data
          .filter((transaction) => transaction.date.slice(5, 7) === '02')
          .sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })
        const datesMarch = response.data.data
          .filter((transaction) => transaction.date.slice(5, 7) === '03')
          .sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })
        const datesApril = response.data.data
          .filter((transaction) => transaction.date.slice(5, 7) === '04')
          .sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })
        const datesMay = response.data.data
          .filter((transaction) => transaction.date.slice(5, 7) === '05')
          .sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })
        const datesJune = response.data.data
          .filter((transaction) => transaction.date.slice(5, 7) === '06')
          .sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })
        const datesJuly = response.data.data
          .filter((transaction) => transaction.date.slice(5, 7) === '07')
          .sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })
        const datesAugust = response.data.data
          .filter((transaction) => transaction.date.slice(5, 7) === '08')
          .sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })
        const datesSeptember = response.data.data
          .filter((transaction) => transaction.date.slice(5, 7) === '09')
          .sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })
        const datesOctober = response.data.data
          .filter((transaction) => transaction.date.slice(5, 7) === '10')
          .sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })
        const datesNovember = response.data.data
          .filter((transaction) => transaction.date.slice(5, 7) === '11')
          .sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })
        const datesDecember = response.data.data
          .filter((transaction) => transaction.date.slice(5, 7) === '12')
          .sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })

        setTransactionsByMonth(() => [
          {
            date: 'January',
            transactions: datesJanuary,
            sumOfTransactionValues: datesJanuary.reduce(
              (previousValue, currentValue) => {
                return previousValue + currentValue.amount
              },
              0
            ),
          },
          {
            date: 'February',
            transactions: datesFebruary,
            sumOfTransactionValues: datesFebruary.reduce(
              (previousValue, currentValue) => {
                return previousValue + currentValue.amount
              },
              0
            ),
          },
          {
            date: 'March',
            transactions: datesMarch,
            sumOfTransactionValues: datesMarch.reduce(
              (previousValue, currentValue) => {
                return previousValue + currentValue.amount
              },
              0
            ),
          },
          {
            date: 'April',
            transactions: datesApril,
            sumOfTransactionValues: datesApril.reduce(
              (previousValue, currentValue) => {
                return previousValue + currentValue.amount
              },
              0
            ),
          },
          {
            date: 'May',
            transactions: datesMay,
            sumOfTransactionValues: datesMay.reduce(
              (previousValue, currentValue) => {
                return previousValue + currentValue.amount
              },
              0
            ),
          },
          {
            date: 'June',
            transactions: datesJune,
            sumOfTransactionValues: datesJune.reduce(
              (previousValue, currentValue) => {
                return previousValue + currentValue.amount
              },
              0
            ),
          },
          {
            date: 'July',
            transactions: datesJuly,
            sumOfTransactionValues: datesJuly.reduce(
              (previousValue, currentValue) => {
                return previousValue + currentValue.amount
              },
              0
            ),
          },
          {
            date: 'August',
            transactions: datesAugust,
            sumOfTransactionValues: datesAugust.reduce(
              (previousValue, currentValue) => {
                return previousValue + currentValue.amount
              },
              0
            ),
          },
          {
            date: 'September',
            transactions: datesSeptember,
            sumOfTransactionValues: datesSeptember.reduce(
              (previousValue, currentValue) => {
                return previousValue + currentValue.amount
              },
              0
            ),
          },
          {
            date: 'October',
            transactions: datesOctober,
            sumOfTransactionValues: datesOctober.reduce(
              (previousValue, currentValue) => {
                return previousValue + currentValue.amount
              },
              0
            ),
          },
          {
            date: 'November',
            transactions: datesNovember,
            sumOfTransactionValues: datesNovember.reduce(
              (previousValue, currentValue) => {
                return previousValue + currentValue.amount
              },
              0
            ),
          },
          {
            date: 'December',
            transactions: datesDecember,
            sumOfTransactionValues: datesDecember.reduce(
              (previousValue, currentValue) => {
                return previousValue + currentValue.amount
              },
              0
            ),
          },
        ])
        setPageMonth((page) => page + 1)
      })

      .catch((error) => {
        console.trace(error)
        setHasMoreDateTransactions(false)
      })
      .finally(() => {
        setIsFetchingDateTransactions(false)
      })
  }, [setHasMoreDateTransactions, pageMonth, isFetchingDateTransactions])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    if (page > 1) return // this is needed because i just want the effect to run once
    fetchTransactions()
    if (pageMonth > 1) return // this is needed because i just want the effect to run once
    fetchTransactionsByMonth()
  }, [fetchTransactions, page, pageMonth, fetchTransactionsByMonth])

  useEffect(() => {
    api
      .get<number>('/statistics/by_type', {
        params: {
          transactionTypeSlug: 'revenues',
        },
      })
      .then(({ data: total }) => setSumOfTransactionValues(total))
      .catch((err) => {
        console.trace('Failed to fetch sum. ', err)
      })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    (month !== '' && (
      <React.Fragment>
        <HeaderFilter
          label="Receitas"
          value={
            month ===
            transactionsByMonth?.find(
              (transaction) => transaction?.date === month
            )?.date
              ? transactionsByMonth.find(
                  (transaction) => transaction?.date === month
                )?.sumOfTransactionValues
              : 0
          }
          isPositive={sumOfTransactionValues > 0}
          pagePath="/revenues/new"
          date={
            <Select
              mr={'1rem'}
              color={'black'}
              backgroundColor={'white'}
              placeholder="Todos os meses"
              onChange={(e) => {
                setMonth(e.target.value)
                fetchTransactionsByMonth()
              }}
            >
              {options.map((option) => (
                <option color="black" key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          }
        />
        <Main>
          <InfiniteScroll
            style={{ height: '100%' }}
            dataLength={transactionsByMonth.length}
            next={fetchTransactionsByMonth}
            hasMore={hasMoreDateTransactions}
            scrollableTarget="scrollableMain"
            scrollThreshold={0.9}
            loader={<div></div>}
            refreshFunction={refreshDateTransactions}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <h4 style={{ textAlign: 'center' }}>
                &#8595; Arraste para baixo para atualizar
              </h4>
            }
            releaseToRefreshContent={
              <h4 style={{ textAlign: 'center' }}>
                &#8593; Solte para atualizar
              </h4>
            }
            endMessage={
              <p style={{ textAlign: 'center', marginTop: 24 }}>
                <b>Você viu todas as suas transações.</b>
              </p>
            }
          >
            {month ===
            transactionsByMonth?.find(
              (transaction) => transaction?.date === month
            )?.date ? (
              transactionsByMonth
                .find((transaction) => transaction?.date === month)
                ?.transactions.map((transaction, index2) => (
                  <TransactionWrapper
                    key={index2}
                    onClick={() => onTransactionClick(transaction)}
                  >
                    <Transaction {...transaction} />
                  </TransactionWrapper>
                ))
            ) : (
              <div>
                <h1>Nenhuma transação encontrada</h1>
              </div>
            )}
            {transactionsByMonth.find(
              (transaction) => transaction?.date === month
            )?.transactions.length === 0 && (
              <div>
                <h1>Nenhuma transação encontrada</h1>
              </div>
            )}
          </InfiniteScroll>
        </Main>
        <NavbarBottom />
      </React.Fragment>
    )) || (
      <React.Fragment>
        <HeaderFilter
          label="Receitas"
          value={sumOfTransactionValues}
          isPositive={sumOfTransactionValues > 0}
          pagePath="/revenues/new"
          date={
            <Select
              mr={'1rem'}
              color={'black'}
              backgroundColor={'white'}
              placeholder="Todos os meses"
              onChange={(e) => {
                setMonth(e.target.value)
                fetchTransactionsByMonth()
              }}
            >
              {options.map((option) => (
                <option color="black" key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          }
        />
        <Main>
          <InfiniteScroll
            style={{ height: '100%' }}
            dataLength={transactions.length}
            next={fetchTransactions}
            hasMore={hasMoreTransactions}
            scrollableTarget="scrollableMain"
            scrollThreshold={0.9}
            loader={<div>Buscando lançamentos...</div>}
            refreshFunction={refreshTransactions}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <h4 style={{ textAlign: 'center' }}>
                &#8595; Arraste para baixo para atualizar
              </h4>
            }
            releaseToRefreshContent={
              <h4 style={{ textAlign: 'center' }}>
                &#8593; Solte para atualizar
              </h4>
            }
            endMessage={
              <p style={{ textAlign: 'center', marginTop: 24 }}>
                <b>Você viu todas as suas transações.</b>
              </p>
            }
          >
            {transactions.map((transaction) => (
              <TransactionWrapper
                key={transaction.id}
                onClick={() => onTransactionClick(transaction)}
              >
                <Transaction {...transaction} />
              </TransactionWrapper>
            ))}
          </InfiniteScroll>
        </Main>
        <NavbarBottom />
      </React.Fragment>
    )
  )
}

export default Revenues
