import React from 'react'
import Chart from 'react-apexcharts'

interface VerticalBarChartProps {
  title: string
  width: string
  height?: string
  series: any
  categories?: any
  showXAxis?: boolean
  colors?: string[]
  yTitleAxis?: string
}

export function VerticalCustomBar({
  categories,
  series,
  title,
  yTitleAxis,
  width,
  height,
  colors,
  showXAxis,
}: VerticalBarChartProps) {
  return (
    <Chart
      type="bar"
      width={width}
      height={height}
      series={series}
      options={{
        title: {
          text: title,
        },
        chart: {
          id: 'basic-bar',
          animations: {
            enabled: true,
            speed: 1200,
          },
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            show: showXAxis,
          },
          categories,
        },
        yaxis: {
          show: true,
          decimalsInFloat: 0,
          title: {
            text: yTitleAxis,
            style: {
              color: '#000',
            },
          },
        },
        theme: {
          palette: 'palette2',
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
            dataLabels: {
              position: 'bottom',
              hideOverflowingLabels: true,
              orientation: 'vertical',
            },
          },
        },
        colors,
      }}
    />
  )
}
