import styled from 'styled-components'

export const Container = styled.li`
  padding: 0.5rem 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & + & {
    border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  }

  svg {
    margin-right: 16px;
  }

  .description {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    text-align: left;

    .category-name {
      user-select: none;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 180px;
    }

    .date {
      user-select: none;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #a6a6a6;
    }
  }
`

export const Value = styled.span<{ isPositive: boolean }>`
  padding: 1px 4px;
  border-radius: 4px;
  background: ${({ isPositive }) => (isPositive ? '#49BA1325' : '#FF5A4F25')};
  color: ${({ isPositive }) => (isPositive ? '#49BA13' : '#FF5A4F')};
  font-size: 11px;
  user-select: none;
`
