import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  height: 100vh;
  width: 100vw;

  background-color: black;
  background-position: top;
`

export const BackgroundImage = styled.img`
  max-width: 90%;
`

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    color: #ffffff;
    margin-bottom: 8px;

    a {
      font-weight: 600;
    }
  }

  button {
    width: 256px;
    height: 41px;
    left: 59px;
    top: 595px;

    background: #ffffff;
    border-radius: 100px;
    border: 0;
  }

  a {
    opacity: 0.7;
    margin-top: 8px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 21px;
    text-align: center;

    color: #ffffff;
  }

  input[type='submit'] {
    color: black;
  }
`

export const FooterText = styled.p`
  display: inline;

  margin-top: 56px;
  padding-bottom: 24px;

  max-width: 80vw;

  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: center;

  color: #ffffff;

  span {
    font-weight: 600;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  input {
    width: 256px;
    height: 41px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 100px;
    border: 0;
    margin: 4px auto;
    color: white;

    &::placeholder {
      text-align: center;
    }

    &[type='submit'] {
      margin-top: 12px;
      background: #ffffff;
    }
  }
`
