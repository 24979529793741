import styled from 'styled-components'

export const Container = styled.nav`
  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 40000;

  filter: drop-shadow(0px -4px 36px rgba(0, 0, 0, 0.05));

  padding: 0 32px;

  width: 100%;
  height: 80px;
  background: white;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    max-width: 680px;
    margin: 0 auto;
  }

  button {
    display: flex;
    background: transparent;
    border: 0;
  }
`
