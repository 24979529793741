import React from 'react'
import { Link } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'

import {
  BackgroundImage,
  Container,
  CTAContainer,
  FooterText,
  Form,
} from './styles'

import backgroundImage from './background.png'

type FormInputs = {
  email: string
  password: string
}

const SignUp: React.FC = () => {
  const { register, handleSubmit } = useForm()
  const onSubmit: SubmitHandler<FormInputs> = (data) =>
    alert(JSON.stringify(data))
  return (
    <Container>
      <BackgroundImage src={backgroundImage} alt="header image" />
      <CTAContainer>
        <p>Faça o seu cadastro</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <input type="text" placeholder="E-mail" {...register('email')} />
          <input
            type="password"
            placeholder="Senha"
            {...register('password')}
          />
          <input type="submit" value="Cadastrar" />
        </Form>
        <p>
          Já possui conta? <Link to="/login">Clique aqui</Link>
        </p>
      </CTAContainer>
      <FooterText>
        Ao continuar, você concorda com os <span>Termos de Serviço</span> e com
        a <span>Política de Privacidade</span> do Finanças Dominadas
      </FooterText>
    </Container>
  )
}

export default SignUp
