import * as yup from 'yup'

export const schema = yup
  .object()
  .shape({
    initialValue: yup
      .string()
      .required('Insira um valor.')
      .typeError('Insira um valor.'),

    interestLcaLciRate: yup
      .string()
      .required('Insira um valor.')
      .typeError('Insira um valor.'),
    interestCdbRate: yup
      .string()
      .required('Insira um valor.')
      .typeError('Insira um valor.'),
    selicPercentage: yup
      .string()
      .required('Insira um valor.')
      .typeError('Insira um valor.'),
  })
  .required()
