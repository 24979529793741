import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { clone } from 'lodash'
import {
  Text,
  Button,
  Heading,
  HStack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Badge,
} from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { Render } from 'components/Render'
import { format } from 'utils/currency'
import { useForm } from 'react-hook-form'
import InputInteger from 'components/Atoms/InputInteger'

type DiscoveryProps = {
  previous(): void
  next(): void
  targetAmount?: number
  monthsToAccomplish?: number
  monthlyWithdrawals?: number
}

export const Distribution: React.FC<DiscoveryProps> = ({
  next,
  previous,
  targetAmount,
  monthlyWithdrawals,
}) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { register } = useForm()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [age, setAge] = useState<number>(32)
  const [expectancy, setExpectancy] = useState<number>(90)
  const [monthsToDecay, setMonthsToDecay] = useState<number>()
  const [anualReturnRate, setAnualReturnRate] = useState<number>(5)

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const remainingYearsOfLife = useMemo(
    () => Math.max(expectancy - age, 0),
    [expectancy, age]
  )

  const yearsOfPositiveBalance = useMemo(
    () => (monthsToDecay ? monthsToDecay! / 12 : '0'),
    [monthsToDecay]
  )

  const hasEnoughMoney = useMemo(
    () => Boolean(yearsOfPositiveBalance > remainingYearsOfLife),
    [yearsOfPositiveBalance, remainingYearsOfLife]
  )

  const isInfinity = monthsToDecay ? monthsToDecay >= 100 * 12 - 1 : false

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  function handleMoneyEvolution(
    initialMoney: number,
    monthlyInterestRate: number,
    monthlyWithdrawal: number
  ) {
    const MAX_ITERATIONS_MONTHS = 300 * 12

    let im = clone(initialMoney)

    for (let index = 0; index < MAX_ITERATIONS_MONTHS; index++) {
      const imi = im
      const plus = imi * monthlyInterestRate
      const less = monthlyWithdrawal
      const imf = imi + plus - less

      im = imf

      if (im <= 0 || index >= MAX_ITERATIONS_MONTHS - 1) {
        console.log('Took', index, 'months to reach 0 or Infinity.')
        setMonthsToDecay(index)
        break
      }
    }
  }

  useEffect(() => {
    if (!targetAmount) return
    if (!anualReturnRate) return
    if (!monthlyWithdrawals) return

    const monthlyParsedReturnRate = Math.pow(1 + anualReturnRate, 1 / 12) - 1

    console.log('Pre function ', {
      targetAmount,
      anualReturnRate,
      monthlyWithdrawals,
      monthlyParsedReturnRate,
    })

    handleMoneyEvolution(
      targetAmount,
      monthlyParsedReturnRate,
      monthlyWithdrawals
    )
  }, [anualReturnRate, monthlyWithdrawals, targetAmount])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <>
      <Heading>Distribuição</Heading>
      <Text mt={2} opacity={0.4} fontSize={12}>
        Esta aba irá estimar se você possui um patrimônio grande o suficiente
        para se aposentar.
      </Text>

      <Box shadow="base" px={4} py={4} borderRadius={8} mt={8}>
        <Heading as="h2" size="md">
          Premissas
        </Heading>

        <Render when={!!targetAmount}>
          <Text mt={4}>Patrimônio alvo</Text>
          <Text fontWeight="bold">{format(targetAmount!)}</Text>
        </Render>

        <Render when={!!monthlyWithdrawals}>
          <Text mt={4}>Retiradas mensais</Text>
          <Text fontWeight="bold">{format(monthlyWithdrawals!)}</Text>
        </Render>
      </Box>

      <Heading as="h2" size="md" mt={8} mb={2}>
        Inputs
      </Heading>

      <InputInteger
        label="Taxa Anual Acima da Inflação (em %)"
        placeholder="Exemplo: 5,25"
        {...register('monthlyReturn', {
          required: true,
          onChange: (e) => {
            console.log({ e })
            setAnualReturnRate(e.target.value / 100)
          },
        })}
      />

      <Text mt={4}>Idade atual ({age})</Text>
      <Slider colorScheme="green" onChange={(val) => setAge(val)}>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb borderColor="green" />
      </Slider>

      <Text mt={4}>Expectaviva de vida ({expectancy})</Text>
      <Slider colorScheme="green" onChange={(val) => setExpectancy(val)}>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb borderColor="green" />
      </Slider>

      <Heading as="h2" size="md" mt={8}>
        Resultados
      </Heading>

      <Render when={!!monthsToDecay}>
        <Text mt={4}>Anos de economias restantes</Text>
        <Text fontWeight="bold">
          {isInfinity ? 'Infinitos' : (monthsToDecay! / 12).toFixed(2)}
        </Text>
      </Render>

      <Render when={true}>
        <Text mt={4}>Anos de vida restante</Text>
        <Text fontWeight="bold">{remainingYearsOfLife}</Text>
      </Render>

      <Render when={true}>
        <Text mt={4}>Possui patrimônio suficiente?</Text>
        {hasEnoughMoney ? (
          <Badge colorScheme="green">SIM</Badge>
        ) : (
          <Badge colorScheme="red">NÃO</Badge>
        )}
      </Render>

      <HStack mt={16}>
        <Button
          colorScheme="gray"
          width="100%"
          leftIcon={<ArrowBackIcon />}
          onClick={previous}
        >
          Voltar
        </Button>

        <Button
          colorScheme="green"
          width="100%"
          rightIcon={<ArrowForwardIcon />}
          onClick={next}
        >
          Recomeçar
        </Button>
      </HStack>
    </>
  )
}
