import {
  Box,
  Button,
  Divider,
  Heading,
  VStack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { v4 as uuid } from 'uuid'
import { useForm, Controller } from 'react-hook-form'
import Input from 'components/Atoms/Input'
import Header from 'components/Header'
import Private from 'layouts/Private'
import React, { useState } from 'react'
import { schema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import InputCurrency from 'components/Atoms/InputCurrency'
import InputInteger from 'components/Atoms/InputInteger'
import { pmt } from 'utils/pmt'
import { parseCurrency } from 'utils/parse-currency-to-float'
import { parsePercentage } from 'utils/parse-percentage'
import { format } from 'utils/currency'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Link, useHistory } from 'react-router-dom'
import InputPercentage from 'components/Atoms/InputPercentage'

type FormInputs = {
  targetName: string
  targetAmount: string
  initialAmount: string
  periodInMonths: number
  monthlyReturn: string
}

export type Objective = FormInputs & { monthlyAmount: number; uuid: string }

const Dreams: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { errors, dirtyFields, isValid },
  } = useForm<FormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const toast = useToast({ position: 'top' })

  const { push } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [monthlyPaymentAmount, setMonthlyPaymentAmount] = useState<number>()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = handleSubmit((formData) => {
    const monthlyPayment = pmt({
      fv: parseCurrency(formData.targetAmount),
      pv: parseCurrency(formData.initialAmount),
      ir: parsePercentage(formData.monthlyReturn),
      np: formData.periodInMonths,
    })

    setMonthlyPaymentAmount(monthlyPayment)
  })

  const onFormBlur = () => {
    setMonthlyPaymentAmount(undefined)
  }

  const onSaveObjective = () => {
    const values = getValues()

    const localStorageItems = localStorage.getItem('@fd:dreams')

    const dreams = localStorageItems
      ? (JSON.parse(localStorageItems) as Objective[])
      : []

    dreams.push({
      ...values,
      monthlyAmount: monthlyPaymentAmount || 0,
      uuid: uuid(),
    })

    localStorage.setItem('@fd:dreams', JSON.stringify(dreams))

    toast({
      title: 'Sucess',
      description: 'Objetivo salvo com sucesso!',
      variant: 'success',
    })

    push('/tools/dreams')
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Private>
      <Header title="Cadastrar um objetivo" />
      <Box h="72vh" p="2rem" pb="106px" overflowY="auto">
        <Box as="form" onSubmit={onSubmit} onBlur={onFormBlur}>
          <VStack spacing={4}>
            <Input
              label="Nome do seu objetivo"
              placeholder="Exemplo: novo carro"
              error={errors.targetName}
              isDirty={!!dirtyFields.targetName}
              {...register('targetName')}
            />

            <InputCurrency
              label="Valor a acumular"
              placeholder="Exemplo: R$ 50.000,00"
              error={errors.targetAmount}
              isDirty={!!dirtyFields.targetAmount}
              {...register('targetAmount')}
            />

            <InputCurrency
              label="Valor inicial"
              placeholder="Exemplo: R$ 50.000,00"
              error={errors.initialAmount}
              isDirty={!!dirtyFields.initialAmount}
              {...register('initialAmount')}
            />

            <InputInteger
              label="Período em meses"
              placeholder="Exemplo: 60"
              error={errors.periodInMonths}
              isDirty={!!dirtyFields.periodInMonths}
              {...register('periodInMonths')}
            />

            <Controller
              name="monthlyReturn"
              control={control}
              render={({ field: { onChange, onBlur, ref } }) => (
                <InputPercentage
                  label="Rentabilidade mensal do investimento"
                  placeholder="Exemplo: 0,5%"
                  error={errors.monthlyReturn}
                  isDirty={!!dirtyFields.monthlyReturn}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  name="monthlyReturn"
                />
              )}
            />

            {/* <InputPercentage
              label="Rentabilidade mensal do investimento"
              placeholder="Exemplo: 0,5"
              error={errors.monthlyReturn}
              isDirty={!!dirtyFields.monthlyReturn}
              {...register('monthlyReturn')}
            /> */}
          </VStack>

          <Divider mt={8} />

          <VStack spacing={4}>
            {!monthlyPaymentAmount ? (
              <>
                <Button
                  colorScheme="green"
                  type="submit"
                  w="100%"
                  mt={8}
                  disabled={!isValid}
                >
                  Calcular aporte mensal mínimo
                </Button>

                <Button
                  variant="ghost"
                  w="100%"
                  as={Link}
                  to="/private/tools/dreams"
                  leftIcon={<ArrowBackIcon />}
                >
                  Voltar
                </Button>
              </>
            ) : (
              <>
                <Heading as="h3" mt={4}>
                  Aporte mensal
                </Heading>
                <Text fontSize={24}>{format(monthlyPaymentAmount)}</Text>

                <Divider mt={8} />

                <Button
                  colorScheme="green"
                  type="button"
                  w="100%"
                  mt={8}
                  disabled={!isValid}
                  onClick={onSaveObjective}
                >
                  Salvar objetivo
                </Button>

                <Button
                  colorScheme="red"
                  variant="outline"
                  w="100%"
                  onClick={() => {
                    setMonthlyPaymentAmount(undefined)
                  }}
                >
                  Limpar dados
                </Button>
              </>
            )}
          </VStack>
        </Box>
      </Box>
    </Private>
  )
}

export default Dreams
