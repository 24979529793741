import React, { useCallback } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'

// Contexts
import { useAuth } from 'contexts/auth'

// Components
import { Loading } from 'components/Loading'

interface CustomRouteProps extends RouteProps {
  isPrivate?: boolean
  component: React.ComponentType
}

const CustomRoute: React.FC<CustomRouteProps> = ({
  isPrivate = true,
  component: Component,
  ...rest
}) => {
  const { isLogged, isLoading } = useAuth()

  const renderRule = useCallback(() => {
    // Auth Loading
    if (isLoading) return <Loading />

    // Logged and accessing private page
    if (isPrivate === isLogged) return <Component />

    // Redirect if none condition above is satisfied
    return <Redirect to={{ pathname: isPrivate ? '/' : '/home' }} />
  }, [Component, isLoading, isLogged, isPrivate])

  return <Route {...rest} render={renderRule} />
}

export default CustomRoute
