import React from 'react'
import Lottie from 'react-lottie'

// Assets
import loading from 'assets/lotties/loading-green.json'

export const Loading = () => (
  <Lottie
    options={{
      loop: true,
      autoplay: true,
      animationData: loading,
    }}
    height={300}
    width={400}
  />
)
