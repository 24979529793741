import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { PlusCircle, Icon } from 'react-feather'
import { Flex, useDisclosure } from '@chakra-ui/react'

import { IoIosMenu } from 'react-icons/io'

import { useAuth } from 'contexts/auth'

import { format } from 'utils/currency'

import {
  Balance,
  Container,
  Row,
  IconButton,
  User,
  Title,
  RowExpense,
} from './styles'
import Sidebar from 'components/Sidebar'

type HeaderProps = {
  label?: string
  value?: number
  isPositive?: boolean
  pagePath?: string
  pagePathIcon?: Icon
  title?: string
  date?: React.ReactNode
}

const HeaderFilter: React.FC<HeaderProps> = ({
  label,
  value,
  isPositive,
  pagePath,
  pagePathIcon: PagePathIcon,
  title,
  date,
}) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { user } = useAuth()
  const { push } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onOpen, onClose } = useDisclosure()

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const placeholderAvatarEndpoint = useMemo(() => {
    if (!user) return ''

    /**
     * See here for more options:
     * https://ui-avatars.com/
     */
    const baseUrl = 'https://ui-avatars.com/api/?'
    const options = 'background=22ff41&color=222&bold=true'

    const name = user.name.split(' ')
    const nameWithDash = name.join('-')

    return `${baseUrl}${options}&name=${nameWithDash}`
  }, [user])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Container>
      <Row>
        <User>
          <img src={user?.avatar || placeholderAvatarEndpoint} alt="profile" />
          <p>{user?.name}</p>
        </User>
        <IconButton onClick={onOpen}>
          <IoIosMenu size={26} />
        </IconButton>
      </Row>
      <RowExpense>
        {title ? (
          <Title>{title}</Title>
        ) : (
          <p>
            {label}
            <br />
            <Balance isPositive={isPositive ?? true}>
              {format(value ?? 0)}
            </Balance>
          </p>
        )}
        <Flex gap={'1rem'} alignItems={'center'}>
          {date}
          {pagePath && (
            <IconButton onClick={() => push(pagePath)}>
              {PagePathIcon ? (
                <PagePathIcon color="white" />
              ) : (
                <PlusCircle color="white" />
              )}
            </IconButton>
          )}
        </Flex>
      </RowExpense>

      <Sidebar onClose={onClose} isOpen={isOpen} />
    </Container>
  )
}

export default HeaderFilter
