import {
  Button,
  VStack,
  Select,
  Heading,
  Divider,
  FormLabel,
  FormControl,
  Input,
  useToast,
  Stack,
} from '@chakra-ui/react'
import { useSubCategories } from 'contexts/sub-categories'
import React, { ReactNode, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { FiArrowLeft } from 'react-icons/fi'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import { api } from 'services/api'

type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  size: Size
  children?: ReactNode
  goBackModal: () => void
} & ReactModalProps

type FormInputs = {
  transactionSubCategoryId: string
  subCategoryName: string
}

const contentStyles = () => {
  return {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0px',
  }
}

const getWidthBySize = (size: Size) => {
  const sizes: Record<Size, string> = {
    sm: '27em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }

  return sizes[size]
}

export const ModalAddNewCategoryRevenues = (props: ModalProps) => {
  const {
    isOpen,
    onClose,
    size = 'md',
    children,
    goBackModal,
    ...reactModalProps
  } = props
  const { revenues } = useSubCategories()
  const toast = useToast({ position: 'top' })
  const { register, getValues } = useForm<FormInputs>({
    mode: 'onBlur',
  })

  const createSubCategory = useCallback(() => {
    api
      .post('/transaction_sub_categories', {
        transactionCategoryId: getValues().transactionSubCategoryId,
        name: getValues().subCategoryName,
      })
      .then(() => {
        toast({
          title: 'Sucesso',
          description: 'Investimento cadastrado com sucesso',
          status: 'success',
          duration: 2000,
        })
      })
      .catch((e) => console.log(e))
    location.reload()
  }, [toast, getValues])
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          ...contentStyles(),
          width: getWidthBySize(size),
          padding: '2rem',
        },
        overlay: {
          zIndex: 999999999,
          backgroundColor: '#000000cc',
        },
      }}
      {...reactModalProps}
    >
      <form>
        <VStack spacing={6}>
          <Heading as="h2" size="md" mb="2" color={'#FCA130'}>
            Adicionar nova sub-categoria
          </Heading>
          <Divider />

          <FormControl>
            <Stack w={'100%'} spacing={3}>
              <FormLabel
                htmlFor="transactionSubCategoryId"
                textAlign="center"
                m="0"
              >
                Categoria
              </FormLabel>
              <Select
                id="category"
                {...register('transactionSubCategoryId', { required: true })}
              >
                {revenues.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
            </Stack>
          </FormControl>
          <FormControl>
            <Stack w={'100%'} spacing={3}>
              <FormLabel htmlFor="subCategoryName" textAlign="center" m="0">
                Sub-Categoria
              </FormLabel>
              <Input
                id="subCategoryName"
                type={'text'}
                {...register('subCategoryName', { required: true })}
              />
            </Stack>
          </FormControl>

          <Button
            onClick={createSubCategory}
            colorScheme="red"
            w="100%"
            mt="4"
            h="48px"
            borderRadius="40px"
            variant="outline"
          >
            Adicionar
          </Button>
          <Button
            onClick={goBackModal}
            variant="ghost"
            leftIcon={<FiArrowLeft />}
          >
            Voltar
          </Button>
        </VStack>
      </form>
      {children}
    </ReactModal>
  )
}
