import React from 'react'
import { DateTime } from 'luxon'
import * as FiIcons from 'react-icons/fc'
import { Text } from '@chakra-ui/react'

import { format } from 'utils/currency'

import { Container, Value } from './styles'

export type TransactionProps = {
  id: string
  amount: number
  date: string
  transactionType: {
    slug: 'revenues' | 'expenses' | 'investments'
  }
  transactionSubCategory: {
    id: string
    name: string
    icon_name: keyof typeof FiIcons
  }
  parent_transaction_id?: string
  installments: string
  recurrence_times: number
  current_recurrence?: number
}

const Transaction: React.FC<TransactionProps> = ({
  amount,
  date,
  transactionSubCategory,
  current_recurrence,
  recurrence_times,
}) => {
  const Icon = FiIcons[transactionSubCategory.icon_name]
  const formattedcurrent_recurrence =
    recurrence_times && recurrence_times > 1 ? `${current_recurrence}` : ''
  const formattedrecurrence_times =
    recurrence_times && recurrence_times > 1 ? `${recurrence_times}` : ''
  return (
    <Container>
      {Icon && <Icon size={22} />}
      <div className="description">
        <p className="category-name">{transactionSubCategory.name}</p>
        <p className="date">
          {DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')}
        </p>
      </div>
      <div>
        <Value isPositive={amount > 0}>{format(amount)}</Value>
        <Text fontSize="10px">
          {recurrence_times > 1
            ? `Parcela ${formattedcurrent_recurrence} de ${formattedrecurrence_times}`
            : ''}
        </Text>
      </div>
    </Container>
  )
}

/* `Parcela {formattedcurrent_recurrence && formattedcurrent_recurrence} de
{formattedrecurrence_times && formattedrecurrence_times}` */
export default Transaction
