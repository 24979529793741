import React from 'react'
import {
  Box,
  Tooltip as Information,
  TooltipProps,
  Text,
} from '@chakra-ui/react'

type ToolTipInfoProps = {
  children: React.ReactNode
  label: string
} & TooltipProps

export const Tooltip = (prop: ToolTipInfoProps) => {
  const { children, label, ...rest } = prop

  return (
    <Information
      bgColor={'white'}
      color={'black'}
      label={<Text whiteSpace={'break-spaces'}>{label}</Text>}
      {...rest}
      textAlign={'justify'}
      closeOnClick={false}
    >
      <Box>{children}</Box>
    </Information>
  )
}
