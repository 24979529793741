import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import { Link, useParams, useHistory } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'

import { api } from 'services/api'

import {
  BackgroundImage,
  Container,
  CTAContainer,
  FooterText,
  Form,
} from './styles'

import backgroundImage from './background.png'

type FormInputs = {
  password: string
  password_confirmation: string
}

const ResetPassword: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { register, handleSubmit } = useForm()
  const { token } = useParams<{ token?: string }>()
  const { push } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    if (data.password !== data.password_confirmation) {
      return Swal.fire('Aviso', 'As senhas não estão iguais', 'error')
    }

    api
      .put('/forgot_password', { ...data, token })
      .then(() => {
        Swal.fire(
          'Sucesso',
          'Senha redefinida com sucesso. Você já pode efetuar o seu login',
          'success'
        )
        push('/login')
      })
      .catch((err) => {
        console.trace(err)
        Swal.fire(
          'Aviso',
          'Houve um erro ao processar o seu pedido. Confirme o dados inserido ou tente novamente mais tarde.',
          'error'
        )
      })
  }

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    if (!token) push('/')
  }, [push, token])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Container>
      <BackgroundImage src={backgroundImage} alt="header image" />
      <CTAContainer>
        <p>Redefina a sua senha</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="password"
            placeholder="Nova senha"
            {...register('password')}
          />
          <input
            type="password"
            placeholder="Confirmar senha"
            {...register('password_confirmation')}
          />
          <input type="submit" value="Solicitar" />
        </Form>
        <Link to="/">Voltar</Link>
      </CTAContainer>
      <FooterText>
        Ao continuar, você concorda com os <span>Termos de Serviço</span> e com
        a <span>Política de Privacidade</span> do Finanças Dominadas
      </FooterText>
    </Container>
  )
}

export default ResetPassword
