import {
  Box,
  Button,
  Heading,
  Text,
  Stat,
  // StatHelpText,
  StatLabel,
  // StatNumber,
  Divider,
  Badge,
} from '@chakra-ui/react'
import Header from 'components/Header'
import Private from 'layouts/Private'
import React, { useEffect } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
// import { format } from 'utils/currency'
import { Objective } from '../New'
import { BiTrash } from 'react-icons/bi'

type StockCardProps = Objective & {
  removeHandler: () => void
}

const StockCard = ({ removeHandler, stockName, points }: StockCardProps) => (
  <Stat
    position="relative"
    border="1px solid #fafafa"
    shadow="md"
    borderRadius={8}
    px={3}
    py={2}
    my={3}
  >
    <StatLabel>
      {stockName} | {points} pontos
    </StatLabel>
    <Badge
      colorScheme={points <= -8 ? 'red' : points <= 8 ? 'yellow' : 'green'}
    >
      RESULTADO
    </Badge>
    <Text textAlign="left" opacity={0.4} fontSize={12}>
      {points <= -8
        ? 'pouco viável para estudo e aquisição'
        : points <= 8
        ? 'moderadamente viável para estudo e aquisição'
        : 'fortemente viável para estudo e aquisição'}
    </Text>

    <Button
      onClick={removeHandler}
      colorScheme="red"
      position="absolute"
      top={2}
      right={2}
    >
      <BiTrash />
    </Button>
  </Stat>
)

const Stocks: React.FC = () => {
  const [dreams, setDreams] = React.useState<Objective[]>([])

  useEffect(() => {
    const localStorageItems = localStorage.getItem('@fd:stocks')

    const dreams = localStorageItems
      ? (JSON.parse(localStorageItems) as Objective[])
      : []

    dreams.sort((a, b) => b.points - a.points)

    setDreams(dreams)
  }, [])

  const onRemoveDream = (dreamUuid: string) => {
    const localStorageItems = localStorage.getItem('@fd:stocks')

    const dreams = localStorageItems
      ? (JSON.parse(localStorageItems) as Objective[])
      : []

    const filteredDrams = dreams.filter((dream) => dream.uuid !== dreamUuid)
    setDreams(filteredDrams)
    localStorage.setItem('@fd:stocks', JSON.stringify(filteredDrams))
  }

  return (
    <Private>
      <Header title="Minhas ações" />
      <Box h="72vh" p="2rem" pb="106px" overflowY="auto">
        {!dreams.length ? (
          <>
            <Heading mb={2}>Você ainda não possui ações registradas</Heading>
            <Text fontSize={24}>
              Cadastre a sua primeira clicando no botão verde
            </Text>
          </>
        ) : (
          <>
            <Heading mb={2}>Ranking de ações</Heading>

            <Divider mt={4} mb={4} />

            {dreams.map((dream) => (
              <StockCard
                removeHandler={() => onRemoveDream(dream.uuid)}
                key={dream.uuid}
                {...dream}
              />
            ))}
          </>
        )}

        <Button
          as={Link}
          colorScheme="green"
          position="fixed"
          bottom="116px"
          right="24px"
          size="lg"
          w="56px"
          h="56px"
          borderRadius="28px"
          to="/tools/stocks/new"
          shadow="xl"
        >
          <AddIcon />
        </Button>
      </Box>
    </Private>
  )
}

export default Stocks
