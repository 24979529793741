export enum TransactionTypesEnum {
  'expenses' = 'gastos',
  'revenues' = 'ganhos',
  'investments' = 'investimentos',
}

export enum TransactionTypesColors {
  'expenses' = '#FF5A4F',
  'revenues' = '#49BA13',
  'investments' = '#7c2ac8',
}

// https://apexcharts.com/docs/options/theme/
export enum TransactionTypesApexPalettes {
  'expenses' = 'palette7',
  'revenues' = 'palette4',
  'investments' = 'palette10',
}

export type TransactionTypeSlugs = keyof typeof TransactionTypesEnum
