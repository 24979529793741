import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { App } from './App'
import reportWebVitals from './reportWebVitals'

console.log('🚀', 'Starting up...')

console.log('Process env vars:', process.env)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://b2c5b9cd220c4a5daca304c88c687450@o1055602.ingest.sentry.io/6622724',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
