import styled from 'styled-components'

export const Container = styled.header`
  width: 100vw;
  height: 28vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: black;

  padding: 1rem 2rem;
  padding-top: 2rem;

  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
`

export const RowExpense = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: white;

  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: white;
`

export const IconButton = styled.button`
  display: flex;
  border: 0;
  background: transparent;
`

export const User = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  p {
    margin-left: 1rem;
  }
`

export const Balance = styled.span<{ isPositive: boolean }>`
  color: ${({ isPositive }) => (isPositive ? '#49BA13' : '#FF5A4F')};
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  display: flex;
  align-items: center;
`

export const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: white;
`
