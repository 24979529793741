import React, { useCallback, useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { VerticalCustomBar } from '../../../../components/Chart/VerticalCustomBar'
import {
  Box,
  Button,
  Divider,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'

import Main from 'components/Main'
import Header from 'components/Header'
import { Loading } from 'components/Loading'
import { compoundInterest } from 'utils/compound-interest'
import { format } from 'utils/currency'

import { FiArrowLeft } from 'react-icons/fi'
import { schema } from './schema'
import InputCurrency from 'components/Atoms/InputCurrency'
import InputInteger from 'components/Atoms/InputInteger'
import Private from 'layouts/Private'
import InputPercentage from 'components/Atoms/InputPercentage'

type FormInputs = {
  initialValue: string
  monthlyValue: string
  interestRate: string
  interestPeriod: 'yearly' | 'monthly'
  periodInMonths: number
}

type ParsedFormInputs = {
  initialValue: number
  monthlyValue: number
  interestRate: number
  interestPeriod: 'yearly' | 'monthly'
  periodInMonths: number
}

const CompoundInterest: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { errors, dirtyFields },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState<
    Record<
      number,
      {
        initialValue: string
        returns: string
        total: string
        monthPayment: string
      }
    >
  >({})
  const [hasResult, setHasResult] = useState(false)
  const [paidAmount, setPaidAmount] = useState(`0`)
  const [finalAmount, setFinalAmount] = useState(`0`)
  const [returnedAmount, setReturnedAmount] = useState(`0`)

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const parseCurrency = useCallback((value: string) => {
    const valueWithoutPrefix = value.replace('R$', '')
    const [integer, decimal] = valueWithoutPrefix.split(',')
    const integerWithoutDots = integer.replace(/\./g, '')
    const beforeParseValue = `${integerWithoutDots}.${decimal}`
    const float = parseFloat(beforeParseValue)

    return float
  }, [])

  const parseDecimal = useCallback((value: string) => {
    const valueWithoutPrefix = value.replace(/\s*/, '')
    const valueWithoutPercentage = valueWithoutPrefix.replace('%', '')
    const [integer, decimal] = valueWithoutPercentage.split(',')
    const float = parseFloat(`${integer}.${decimal}`)

    return float
  }, [])

  const parseFormValues = useCallback(
    (formValues: FormInputs): ParsedFormInputs => {
      const {
        initialValue,
        monthlyValue,
        interestRate,
        interestPeriod,
        periodInMonths,
      } = formValues

      return {
        initialValue: parseCurrency(initialValue),
        monthlyValue: parseCurrency(monthlyValue),
        interestRate: parseDecimal(interestRate),
        interestPeriod,
        periodInMonths,
      }
    },
    [parseCurrency, parseDecimal]
  )

  const onSubmit: SubmitHandler<FormInputs> = useCallback(
    (formData) => {
      const parsedFormData = parseFormValues(formData)
      setIsLoading(true)

      const {
        initialValue,
        monthlyValue,
        interestRate,
        interestPeriod,
        periodInMonths,
      } = parsedFormData

      const monthlyInterestRate =
        interestPeriod === 'monthly'
          ? interestRate / 100
          : Math.pow(1 + interestRate / 100, 1 / 12) - 1

      const compund = compoundInterest({
        initialPayment: initialValue,
        monthPayment: monthlyValue,
        monthsDuration: periodInMonths,
        monthlyInterestRate,
      })

      setResult(compund)

      const finalAmount = Number(compund[periodInMonths - 1].total)
      setFinalAmount(format(finalAmount))

      const paidAmount = initialValue + monthlyValue * periodInMonths
      setPaidAmount(format(paidAmount))

      const returnedAmount = Object.values(compund)
        .map(({ returns }) => Number(returns))
        .reduce((acc, cur) => acc + cur, 0)
      setReturnedAmount(format(returnedAmount))

      setTimeout(() => {
        setIsLoading(false)
        setHasResult(true)
      }, 800)
    },
    [parseFormValues]
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Private>
      <Header label="Balanço" value={0} isPositive title={`Juros Compostos`} />
      <Main>
        {!!isLoading && <Loading />}

        {!isLoading && !hasResult && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={8}>
              <Box w="100%">
                <InputCurrency
                  label="Valor do inicial"
                  placeholder="Exemplo: 10.000"
                  error={errors.initialValue}
                  isDirty={!!dirtyFields.initialValue}
                  {...register('initialValue', {
                    required: true,
                  })}
                />
              </Box>

              <Box w="100%">
                <InputCurrency
                  label="Valor do aporte mensal"
                  placeholder="Exemplo: 500"
                  error={errors.monthlyValue}
                  isDirty={!!dirtyFields.monthlyValue}
                  {...register('monthlyValue', {
                    required: true,
                  })}
                />
              </Box>

              <Box w="100%">
                <Text textAlign={'center'} mb="2">
                  Período da taxa de juros
                </Text>
                <RadioGroup defaultValue={getValues().interestPeriod}>
                  <Stack spacing={5} direction="row" justifyContent="center">
                    <Radio
                      colorScheme="green"
                      value="monthly"
                      {...register('interestPeriod')}
                    >
                      Mês
                    </Radio>
                    <Radio
                      colorScheme="green"
                      value="yearly"
                      {...register('interestPeriod')}
                    >
                      Ano
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Box>

              <Box w="100%">
                <Controller
                  name="interestRate"
                  control={control}
                  render={({ field: { onChange, onBlur, ref } }) => (
                    <InputPercentage
                      label="Taxa de juros"
                      error={errors.interestRate}
                      isDirty={!!dirtyFields.interestRate}
                      {...register('interestRate', {
                        required: true,
                      })}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      placeholder="Exemplo: 1,5%"
                    />
                  )}
                />
              </Box>

              <Box w="100%">
                <InputInteger
                  label="Período em meses"
                  error={errors.periodInMonths}
                  isDirty={!!dirtyFields.periodInMonths}
                  placeholder="Ex: 12"
                  {...register('periodInMonths', {
                    required: true,
                  })}
                />
              </Box>
            </VStack>

            <VStack spacing={4} w="100%">
              <Button
                type="submit"
                h="48px"
                borderRadius="40px"
                colorScheme="green"
                mt="8"
                w="100%"
              >
                Calcular
              </Button>

              <Button
                type="button"
                variant="outline"
                h="48px"
                borderRadius="40px"
                mt="8"
                w="100%"
                colorScheme="red"
                onClick={() => reset()}
              >
                Limpar formulário
              </Button>
            </VStack>
          </form>
        )}

        {!!hasResult && (
          <Box>
            <Button
              leftIcon={<FiArrowLeft />}
              onClick={() => setHasResult(false)}
              mb="4"
            >
              Refazer
            </Button>

            <Divider mb={4} />

            <VStack w="100%" alignItems="flex-start" spacing={4} mb={4}>
              <Heading>Resultado</Heading>

              <Heading size="md">
                Valor ao final do período <br /> {finalAmount}
              </Heading>
              <Heading size="sm" opacity={0.4}>
                Valor total aplicado <br /> {paidAmount}
              </Heading>
              <Heading size="sm" opacity={0.4}>
                Valor total rendido <br /> {returnedAmount}
              </Heading>
            </VStack>

            <Divider mb={4} />

            <Heading mb={4}>Gráfico</Heading>
            <VerticalCustomBar
              title="Evolução dos juros"
              showXAxis={true}
              categories={Object.keys(result).map((key) => Number(key) + 1)}
              width="100%"
              yTitleAxis="Valor (R$)"
              series={[
                {
                  name: 'Evolução Mensal',
                  data: Object.values(result).map(({ total }) => Number(total)),
                },
              ]}
            />

            <Divider mb={4} />

            <Heading mb={4}>Tabela</Heading>

            <Box overflowX="auto">
              <Table>
                <Thead>
                  <Tr>
                    <Th>Mês</Th>
                    <Th>Vlr. inicial</Th>
                    <Th>Rendimento</Th>
                    <Th>Aporte</Th>
                    <Th>Vlr. final</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.entries(result).map(([monthIndex, monthData]) => (
                    <Tr key={`month-${monthIndex}`}>
                      <Td>{Number(monthIndex) + 1}</Td>
                      <Td>
                        <Text fontSize={12}>R$ {monthData.initialValue}</Text>
                      </Td>
                      <Td>
                        <Text fontSize={12}>R$ {monthData.returns}</Text>
                      </Td>
                      <Td>
                        <Text fontSize={12}>R$ {monthData.monthPayment}</Text>
                      </Td>
                      <Td>
                        <Text fontSize={12}>R$ {monthData.total}</Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
        )}
      </Main>
    </Private>
  )
}

export default CompoundInterest
