import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'

import { App as Routes } from '../src/routes'
import { GlobalStyle } from './styles/global'

import { AppProvider } from 'contexts'

export const App: React.FC = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <GlobalStyle />
        <ChakraProvider>
          <AppProvider>
            <Routes />
          </AppProvider>
        </ChakraProvider>
      </BrowserRouter>
    </React.StrictMode>
  )
}
